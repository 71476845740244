@charset "utf-8";
/*
Template Name: Costic - Multipurpose Bootstra 4 Admin Dashboard
Author: Metropolitanthemes
Author URL: https://themeforest.net/user/metrotpolitanthemes
Version: 1.0.0
*/
/*===========================
 =  Table of Contents
    * Keyframes
  	* Core Styles
      - Colors
      - Style Reset
      - Typography
      - Main Panel Module
      - Body Content
      - Navbar
      - Aside Navigation
        - Aside Right
        - Quickbar
      - Footer
      - Dropdowns
    * Widgets
    * Forms
      - Form Elements
      - Form Validation
      - Form Wizard
    * UI Elements
      - Accordions
      - Alerts
      - Breadcrumbs
      - Cards
      - Progress Bars
      - Buttons
      - Badges
      - Pagination
      - Prelaoders
      - Tabs
      - Tooltips
      - Lists
    * Advanced UI Elements
      - Draggables
      - Sliders
      - Modals
      - Rating
      - Tour
      - Croppable
      - Range Sliders
    * Animations
    * Icons
    * Tables
      - Basic Tables
      - Data Tables
    * Maps
      - Google Maps
    * Prebuilt pages
      - Default Login and Signup
      - Modal Login and Signup
      - Lock screen
      - Error 404
      - Coming Soon
      - User Profile
      - Portfolio
      - Invoice
    * Apps
      - Chat
      - To-do-list
      - Email
    * Dashboards
      - Web Analytics
      - Crypto Currency
      - Social Media
      - Client Management
    * Dark Theme
    ** SETTINGS PANEL **
    ** WELCOME PAGE **

===========================*/

/* ============= Keyframes ================*/
@-webkit-keyframes notesMembers {
  0%{
    transform: scale(0.5);
  }
  100%{
  transform: scale(1);
  }
}
@keyframes notesMembers {
  0%{
    transform: scale(0.5);
  }
  100%{
  transform: scale(1);
  }
}
@-webkit-keyframes ms-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}
@keyframes ms-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  } 100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@-webkit-keyframes ms-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}
@keyframes ms-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
@-webkit-keyframes ms-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}
@keyframes ms-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
@-webkit-keyframes ms-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}
@keyframes ms-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
@-webkit-keyframes ms-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes ms-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
@-webkit-keyframes ms-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes ms-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}
@-webkit-keyframes ms-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}
@keyframes ms-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
@-webkit-keyframes ms-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes ms-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
@-webkit-keyframes ms-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes ms-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes ms-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
@keyframes ms-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
@-webkit-keyframes ms-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes ms-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes ms-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}
@keyframes ms-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}



/* Colors */
.ms-text-primary,
.ms-text-primary:hover,
.ms-text-primary:focus{
  color: #ff5757;
}
.ms-text-secondary,
.ms-text-secondary:hover,
.ms-text-secondary:focus{
  color: #445cc8;
}
.ms-text-primary,
.ms-text-primary:hover,
.ms-text-primary:focus{
  color: #ff5757;
}
.ms-text-success,
.ms-text-success:hover,
.ms-text-success:focus{
  color: #5cb85c;
}
.ms-text-info,
.ms-text-info:hover,
.ms-text-info:focus{
  color: #5bc0de;
}
.ms-text-warning,
.ms-text-warning:hover,
.ms-text-warning:focus{
  color: #ff575754;
}
.ms-text-danger,
.ms-text-danger:hover,
.ms-text-danger:focus{
  color: #d9534f;
}

.ms-dark-theme .ms-text-danger{
  color: #ffffff;
}

.ms-text-dark,
.ms-text-dark:hover,
.ms-text-dark:focus{
  color: #34334a;
}
.bg-primary{
  background-color: #ff5757!important;
}

.ms-dark-theme .bg-primary{
  background-color: #ffffff;
  color:#ff5757;
}
.bg-secondary{
  background-color: #000000!important;
}
.bg-success{
  background-color: #5cb85c!important;
}
.bg-info{
  background-color: #5bc0de!important;
}

.ms-panel-body .description{
  padding-bottom: 30px;
}
.bg-warning{
  background-color: #ff575747!important;
}
.bg-danger{
  background-color: #d9534f!important;
}
.bg-dark{
  background-color: #34334a!important;
}
.bg-light{
  background-color: #878793!important;
}
.bg-gradient-primary{
  background-color: #0ab1e8;
  background-image: linear-gradient(to right, #0ab1e8, #28f39a);
}
.bg-gradient-secondary{
  background-color: #926afe;
  background-image: linear-gradient(to right, #926afe, #bd9aff);
}
.bg-gradient-success{
  background-color: #12b252;
  background-image: linear-gradient(to right, #12b252, #31e97a);
}
.bg-gradient-warning{
  background-color: #fea837;
  background-image: linear-gradient(to right, #fea837, #fed257);
}
.bg-gradient-danger{
  background-color: #eb025d;
  background-image: linear-gradient(to right, #eb025d, #fe4749);
}
.bg-gradient-info{
  background-color: #3e60ff;
  background-image: linear-gradient(to right, #3e60ff,#5ba0fd);
}
.bg-gradient-light{
  background-color: #5f5f67;
  background-image: linear-gradient(to right, #5f5f67, #858591);
}
.bg-gradient-dark{
  background-color: #252434;
  background-image: linear-gradient(to right, #252434, #3a3850);
}
.bg-facebook{
  background-color: #3b5997;
}
.bg-twitter{
  background-color: #3da4f1;
}
.bg-linkedin{
  background-color: #0177b5;
}
.bg-instagram{
  background: linear-gradient(to top right, #fa7e1e, #d82a74, #9a2ebc, #4f5bd5);
}
.bg-youtube{
  background-color: #fe0000;
}
.bg-g-plus{
  background-color: #dc4e41;
}
.bg-pinterest{
  background-color: #bd071d;
}
.bg-dropbox{
  background-color: #3d9ae8;
}
.bg-dribble{
  background-color: #ea4c89;
}

.text-red{
  color: #ff5757;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.content-overflow
{
  width: 100%;
  overflow: auto;
}

/* Style Reset */
*:focus{
  outline: 0;
  border-color: transparent;
}
body{
  padding: 0;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  list-style: none;
  padding: 0;
}

.ms-scrollable,
.ms-aside-scrollable{
  position: relative;
  overflow-y: auto;
}
.ms-directions{
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #76838f;
  font-size: 12px;
}
.table td, .table th{
  white-space: nowrap;
  vertical-align: middle;
}
/* Typography */

body{
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6{
  color: #000000;
  font-family: 'Roboto', sans-serif;
}
h1{
  font-size: 60px;
  font-weight: 500;
}
h2{
  font-size: 48px;
  font-weight: 500;
}
h3{
  font-size: 30px;
  font-weight: 500;
}
h4{
  font-size: 24px;
}
h5{
  font-size: 21px;
  font-weight: 500;
}
h6{
  font-size: 16px;
}
p{
  font-size: 14px;
  margin-bottom: 15px;
}
p,
span,
.ms-dark-theme .driver-highlighted-element p,
.driver-highlighted-element p,
.driver-highlighted-element span,
.ms-dark-theme .driver-highlighted-element span{
  color: #333333;
}
a{
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #ff5757;
  font-size: 14px;
}
a:hover, a:active, a:focus {
  color: #bc2634;
  text-decoration: none;
}
code{
  padding: 5px;
  font-weight: 300;
  font-size: 0.8125rem;
  border-radius: 4px;
      color: #ff5757
}
pre{
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  color: #333333;
  margin-top: 2rem;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.material-icons{
  vertical-align: sub;
  font-weight: 500;
  font-size: 14px;
}
.text-disabled{
  color: #878793;
}
.fs-10{
  font-size: 10px;
}
.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
  font-size: 16px;
}
.medium{
  font-weight: 500;
}
.bold{
  font-weight: 700;
}
.section-title{
  margin: 0 0 1.5rem 0;
  font-size: 14px;
}
.w-5{
  width: 5%;
}

/* Main Panel Module */
.ms-panel {
  background: #fff;
  -webkit-box-shadow: 0 1px 6px 1px rgba(69,65,78, 0.1);
  -moz-box-shadow: 0 1px 6px 1px rgba(69,65,78,0.1);
  box-shadow: 0 1px 6px 1px rgba(69,65,78,0.1);
  margin-bottom: 30px;
}
.ms-panel.has-border{
  border: 1px solid rgba(0,0,0,.1);
}
.ms-panel.ms-panel-hoverable:hover{
  background-color: #f5f5f5;
}
.ms-panel.bg-dark{
  background-color: #34334a;
}
.ms-panel.ms-panel-fh{
  height: calc( 100% - 2rem );
}
.ms-panel-header {
  position: relative;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ms-panel-header.header-mini{
  border-bottom: 0;
  padding-bottom: 0;
}
.ms-panel-header p {
  font-size: 13px;
  margin: 0.25rem 0 0 0;
}
.ms-panel-header h6{
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
}
.ms-panel-header .btn{
  margin-top: 0;
}

.new .grid-btn{
  min-width: 0px;
}

.ms-panel-body,
.ms-panel-footer{
  position: relative;
  padding: 1.5rem;
  overflow-x: auto;
  padding-right: 1.5rem !important;
}
.ms-img-small{
  width: 40px;
}
.ms-img-medium{
  width: 65px;
}
.ms-img-large{
  width: 100px;
}

/* Body Content */
.body-content {
  position: relative;
  background: #f0f0fa;
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
  min-height: 100vh;
}
.horizontal-layout .body-content{
  padding-left: 0;
}
.ms-has-quickbar .body-content{
  padding-right: 60px;
}
.ms-aside-open .body-content{
  padding-left: 255px;
}
.ms-content-wrapper{
  padding: 30px;
}
.has-chevron{
  position: relative;
}
.has-chevron::after {
  content: '\e313';
  font-family: 'Material Icons';
  font-weight: 700;
  font-style: normal;
  position: absolute;
  top: 16px;
  right: 20px;
  transition: 0.3s;
}
.ms-d-block-lg{
  display: block;
}
.ms-d-block-sm{
  display: none;
}
.ms-inline{
  display: inline-block;
  width: auto;
  height: 100%
}
.ms-img-round{
  border-radius: 50%;
}
.ms-has-notification{
  position: relative;
}
.ms-has-notification::after{
  content: '';
  position: absolute;
  top: -6px;
  right: -3px;
  border-radius: 50%;
  border: 1px solid #fff;
  width: 10px;
  height: 10px;
  background: #ff5757;
}
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute;
}
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 6px;
  right: 0;
  position: absolute;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: rgba(0,0,0,0.1);
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  opacity: 0.9;
}
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute;
}
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 0;
  position: absolute;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 7px;
}
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
html{
  overflow-x: hidden;
  overflow-y: auto;
}
.body{
  overflow: hidden;
}
/* Navbar */
.navbar {
  border-radius: 0;
  background: #fff;
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 0 30px;
}
.navbar .navbar-nav li a:hover{
  background: transparent;
  color: #374eae;
}
.ms-aside-toggler,
.ms-nav-toggler{
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  cursor: pointer;
}
.ms-aside-toggler .ms-toggler-bar,
.ms-nav-toggler .ms-toggler-bar{
  height: 2px;
  width: 20px;
  margin-bottom: 4px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: block;
}
.ms-body:not(.ms-aside-left-open) .ms-aside-toggler .ms-toggler-bar{
  width: 20px;
}
.ms-aside-toggler .ms-toggler-bar:nth-child(2){
  width:15px;
}
.ms-aside-toggler .ms-toggler-bar:nth-child(3){
  width:10px;
}
.ms-aside-toggler:hover .ms-toggler-bar:nth-child(2),
.ms-aside-toggler:hover .ms-toggler-bar:nth-child(3){
  width: 20px;
}
.ms-navbar .ms-form input.ms-form-input{
  width: 250px;
}
.navbar-nav li.ms-nav-item{
  margin: 0 25px;
}
.ms-inline li.ms-nav-item{
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  padding: 1.1rem 25px;
  /* position: static; */
}
.ms-navbar li.ms-nav-item .ms-user-img{
  display: block;
  max-width: 40px;
  cursor: pointer;
}
.ms-navbar .ms-nav-list li.ms-nav-item:last-child{
  padding-right: 0;
}
.ms-inline li.ms-nav-item a i[class^="flaticon-"]::before,
.ms-inline li.ms-nav-item a i[class*=" flaticon-"]::before{
  font-size: 22px;
}
.ms-navbar .dropdown-menu{
  min-width:300px;
  margin: 10px auto;
}
.navbar .has-chevron::after{
  top: 0;
  right: auto;
  left: 110%;
}
.ms-horizontal-layout .ms-navbar{
  justify-content: flex-end;
}
.ms-horizontal-layout .ms-navbar .logo-sn{
  flex: 1;
}
.ms-horizontal-layout .ms-panel .navbar{
  padding: 0;
}
.ms-horizontal-layout .ms-panel .navbar .nav-item{
  position: relative;
  flex: 1;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item{
  font-size: 14px;
  padding: 0.5rem 0;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item.active{
  border-bottom: 3px solid #445cc8;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li a.media:hover{
  background: #e4e3e8;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item.active > a,
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item.active > a span,
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item > a:hover span,
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item > a:hover span.has-chevron::after,
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item.active > a .has-chevron::after{
  color: #445cc8;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item > a{
  display: block;
  padding: 0.5rem;
  border-right: 1px solid rgba(0,0,0,0.1);
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item:last-child > a{
  border-right: 0;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item .dropdown-menu{
  width: 100%;
}
.ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item > a span i{
  margin-right: 5px;
}

/* Aside Navigation */
.side-nav {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 255px;
  margin: 0;
  height: 100%;
  float: left;
  padding-bottom: 3.75rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 999;
  overflow-y: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.3s transform;
  transition: 0.3s transform;
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15), 0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.fixed,
.side-nav.fixed {
  position: fixed;
  left: 0;
}
.ms-aside-left.side-nav{
  transform: translateX(-100%);
}
.ms-aside-left-open .ms-aside-left.side-nav{
  transform: translateX(0);
}
.ms-aside-left-open .body-content{
  padding-left: 255px;
}
.ms-aside-right-open .body-content{
  padding-right: 350px;
}
.side-nav ul {
  list-style-type: none;
  list-style: none;
  padding-left: 0;
}
.side-nav .logo-sn,
.side-nav .menu-item ul {
  background-color: #ebebeb;
}
.side-nav .logo-sn a{
  display: block;
  /* padding: 17px 0; */
  padding: 5px;
  
  
}
 .logo-sn a img {
  
  
} 
.side-nav ul li.menu-item {
  padding: 0;
}
.side-nav .menu-item a {
  position: relative;
  display: block;
  padding: 15px 0 15px 25px;
  color: #000000de;
  -webkit-transition: 0s;
  transition: 0s;
}
.ms-dark-theme .side-nav .menu-item a {
  position: relative;
  display: block;
  padding: 15px 0 15px 25px;
  color: #ffffff;
  -webkit-transition: 0s;
  transition: 0s;
}
.side-nav .menu-item a span{
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #000000de;
}
.side-nav .menu-item a.active::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #ff5757;
}
.side-nav .menu-item ul li a.active::before{
  left: auto;
  right: 0;
  top: 12px;
  height: 30px;
}
.side-nav li.menu-item a:hover,
.side-nav li.menu-item a:hover span,
.side-nav li.menu-item a.active,
.side-nav li.menu-item a.active span,
.side-nav li.menu-item ul li a.active{
  color: #ff5757;
}
.side-nav .menu-item ul {
  padding-left: 40px;
}

.side-nav .has-chevron:hover::after{
  color: #ff5757;
}
.side-nav a[aria-expanded="false"]::after {
  transform: rotate(0);
}
.side-nav .menu-item.open-nav>a::after{
  transform: rotate(180deg);
}
.side-nav li.menu-item a i {
  margin-right: 20px;
}

/* Aside Right */
.side-nav.ms-aside-right{
  transform: translate(100%);
  left: auto;
  right: 0;
  top: 0;
  min-width:350px;
  z-index: 1001;
  background: #fff;
}
.side-nav.ms-aside-right.ms-aside-open{
  transform: translate(0);
  -webkit-box-shadow: 2px 0 75px 0 rgba(0,0,0,.16);
  -moz-box-shadow: 2px 0 75px 0 rgba(0,0,0,.15);
  box-shadow:2px 0 75px 0 rgba(0,0,0,.16);
}
.ms-aside-header{
  padding: 15px 25px 0;
}
.ms-aside-header ul li{
  flex: 2;
}
.ms-aside-header ul li:last-child{
  flex: 1;
  text-align: center;
  padding: 4px;
}
.ms-aside-header ul li:last-child button.close{
  float: none;
}

.ms-aside-body{
  padding: 15px 25px;
}

ul.ms-activity-log > li{
  position: relative;
  margin: 20px 0 35px;
  padding-left: 50px;
}
ul.ms-activity-log > li:first-child{
  margin-top: 0;
}
ul.ms-activity-log > li::before{
  content: ' ';
  background: rgba(0,0,0,.1);
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 14px;
  width: 1px;
  height: 125%;
  z-index: 400;
}
ul.ms-activity-log > li:last-child::before{
  display: none;
}
ul.ms-activity-log h6{
  font-size: 15px;
  margin-bottom: 2px;
}
ul.ms-activity-log span{
  font-size: 10px;
}
ul.ms-activity-log span i{
  margin-right: 5px;
}

ul.ms-activity-log .icon{
  position: absolute;
  left: -3px;
  top: -3px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 400;
}
ul.ms-activity-log > li:hover .icon{
  -webkit-box-shadow: 1px 3px 12px rgba(0,0,0,0.4);
  box-shadow: 1px 3px 12px rgba(0,0,0,0.4);
}

.ms-img-overlay-link{
  position: relative;
}
.ms-img-overlay-link::before{
  content: '\f0c1';
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  background: rgba(0,0,0,0.3);
  border-radius: 50%;
  text-align: center;
  color: #fff;
  line-height: 50px;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0, 4.46, 1, 0.35);
  transition: 0.3s cubic-bezier(0, 4.46, 1, 0.35);
  visibility: hidden;
  transform: scale(0.5);
}
.ms-img-overlay-link:hover::before{
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.ms-recent-post img{
  border-radius: 3px;
}
.ms-recent-post h6{
  font-size: 15px;
  margin: 2px 0;
}
.ms-recent-post span{
  font-size: 10px;
  margin-right: 5px;
}
.ms-aside-overlay.ms-overlay-right{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  background: rgba(0,0,0,0.1);
}

/* Quick Bar */

.ms-quick-bar{
  height: 100%;
  left: auto;
  top: 0;
  right: 0;
  width: 60px;
  max-width: 60px;
  border-left: 1px solid rgba(0,0,0,0.1);
  background-color: #fff;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 12;
}
.ms-body:not(.ms-has-quickbar) .ms-quick-bar{
  right: -60px;
}
.ms-qa-configure-mode .ms-quick-bar-item i::after{
  content: '\e15b';
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 700;
  position: absolute;
  top: -2px;
  left: 80%;
  width: 15px;
  height: 15px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  color: #d9534f;
  border-radius: 50%;
  font-size: 10px;
}
.ms-chat-container .ms-quickbar-container,
.ms-quickbar-container{
  height: calc(100vh - 120px);
}
.ms-quick-bar-title{
  font-size: 18px;
  margin: 0.5rem 0;
}
.ms-quick-bar .nav-tabs .nav-item .nav-link{
  background: #fff;
  padding: 15px 0;
}
.ms-quick-bar .nav-tabs.ms-quick-bar-list .nav-item{
  flex: 0 0 auto;
}
.ms-quick-bar .ms-qa-configure-mode.nav-tabs.ms-quick-bar-list .nav-item{
  flex: 1 1 auto;
}
.ms-quick-bar-list{
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  -webkit-transition:  0.3s;
  transition:  0.3s;
}
.ms-quick-bar .ms-quick-bar-list.ms-qa-configure-mode{
  height: auto;
}
.ms-quick-bar-item,
.ms-configure-qa{
  position: relative;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
}
.ms-quick-bar-item i,
.ms-configure-qa i{
  position: relative;
  color: #878793;
}
.ms-quick-bar-item a:not(.active):hover span,
.ms-quick-bar-item a:not(.active):hover i,
.ms-quick-bar .ms-quick-bar-list:not(.ms-qa-configure-mode) + .ms-configure-qa a:hover i,
.ms-quick-bar .ms-quick-bar-list:not(.ms-qa-configure-mode) + .ms-configure-qa a:hover span{
  color: #34334a;
}
.ms-quick-bar-item .ms-quick-bar-label,
.ms-configure-qa .ms-quick-bar-label{
  display: block;
  font-size: 12px;
}
.ms-configure-qa{
  position: absolute;
  bottom: 0;
  padding: 1rem 0;
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-bottom: 0;
}
.ms-configure-qa a:focus,
.ms-configure-qa a:hover{
  color: inherit;
}
.ms-qa-configure-mode + .ms-configure-qa{
  background: #5cb85c;
}
.ms-qa-configure-mode + .ms-configure-qa a,
.ms-qa-configure-mode + .ms-configure-qa span{
  transition: 0s;
  color: #fff;
}
.ms-configure-qa a{
  display: block;
  text-align: center;
}
.ms-quick-bar .nav-tabs .nav-item .nav-link.active .ms-quick-bar-label,
.ms-quick-bar .nav-tabs .nav-item .nav-link.active i,
.ms-qa-configure-mode + .ms-configure-qa i{
  color: #fff;
}
.ms-quick-bar .ms-quick-bar-header{
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ms-quick-bar .ms-quick-bar-body{
  padding: 1rem 0;
}


.ms-quick-bar.ms-quick-bar-open .ms-quick-bar-content{
  transform: translateX(-1px);
  -webkit-box-shadow: -3px 2px 5px 0 rgba(0,0,0,.16);
  -moz-box-shadow: -3px 2px 5px 0 rgba(0,0,0,.15);
  box-shadow: -3px 2px 5px 0 rgba(0,0,0,.16);
}
.ms-quick-bar.ms-quick-bar-open .ms-qa-configure-mode + .ms-configure-qa + .ms-quick-bar-content,
.ms-quick-bar .ms-quick-bar-content{
  position: absolute;
  top: 0;
  right: 100%;
  background: #fff;
  height:100%;
  min-width: 400px;
  -webkit-transform: translateX( 120% );
  transform: translateX( 120% );
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
}
.ms-card.ms-qa-card{
  margin: 0 1rem 1rem 1rem;
}
.ms-qa-options{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.ms-qa-options a{
  font-size: 12px;
}
.ms-note-members li{
  width: 30px;
  float: left;
  margin-left: -10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-animation: notesMembers 0.3s cubic-bezier(0, 4.46, 1, 0.35) 1 forwards;
  animation: notesMembers 0.3s cubic-bezier(0, 4.46, 1, 0.35) 1 forwards;
}
.ms-note-members li:first-child{
  margin-left: 0;
}
.ms-note-members li img{
  border-radius: 50%;
  cursor: pointer;
}
i.ms-delete-trigger:hover,
a.ms-delete-trigger:hover,
.ms-dark-theme a.ms-delete-trigger:hover{
  color: #d9534f;
  cursor: pointer;
}
.ms-deletable .close{
  color: #878793;
}
i.ms-confirm-trigger:hover,
a.ms-confirm-trigger:hover{
  color: #5CB85C;
  cursor: pointer;
}
.ms-qa-card .dropdown-menu{
  min-width: 200px;
}
.ms-qa-card .dropdown-menu .media img{
  width: 30px;
}
.ms-qa-card .ms-dropdown-list{
  max-height: 10rem;
}
.ms-invite-member{
  width: 60%;
  margin: 0 auto;
}
.ms-invite-member i::before{
  font-size: 80px;
}


@media ( max-width: 1024px ){

  .ms-content-wrapper{
    padding: 1.5rem 15px;
  }
  .ms-d-block-lg{
    display: none;
  }
  .ms-d-block-sm{
    display: block;
  }
  .ms-aside-left-open  .ms-aside-left.side-nav{
    transform: translateX(-100%);
  }
  .ms-aside-left.ms-aside-open.side-nav{
    transform: translateX(0);
  }
  .body-content,
  .ms-has-quickbar .body-content,
  .ms-aside-left-open .body-content{
    padding-right: 0;
    padding-left: 0;
  }
  .ms-aside-right-open .body-content{
    padding-right: 0;
  }
  .ms-aside-overlay.ms-overlay-left{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 998;
    background-color: rgba(0,0,0,0.3);
  }
  .navbar,
  .ms-logged-out .navbar{
    padding: 0.25rem 15px;
  }
  .ms-navbar .ms-nav-list{
    position: absolute;
    width: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
    z-index: 10;
    text-align: right;
    padding: 0 1.25rem;
    opacity: 0;
    visibility: hidden;
  }
  .ms-dark-theme .ms-navbar .ms-nav-list{
    background-color: #252851;
  }
  .ms-navbar .ms-nav-list.ms-inline{
    -webkit-transition: 0.3s;
    transition: 0.3s;
    height: auto;
  }
  .ms-nav-list.ms-slide-down{
    top: 100%;
    opacity: 1;
    visibility: visible;
  }
  .ms-nav-toggler{
    border-left: 0;
  }
  .ms-navbar .ms-nav-list .ms-search-form{
    display: none;
  }

  .ms-horizontal-layout .ms-panel .navbar{
    padding: 0.25rem 15px;
  }
  .ms-horizontal-layout .ms-panel .navbar .ms-nav-toggler{
    padding: .25rem 0;
  }
  .ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item > a{
    border-right: 0;
  }
  .ms-horizontal-layout .ms-panel .navbar .navbar-nav .dropdown-menu{
    display: none;
  }
  .ms-horizontal-layout .ms-panel .navbar .navbar-nav .dropdown-menu.show{
    display: block;
  }
  .ms-horizontal-layout .ms-panel .navbar .navbar-nav li.nav-item.active{
    border-bottom: 0;
  }
  .ms-horizontal-layout .ms-panel .navbar .navbar-nav .dropdown-menu a{
    justify-content: center;
  }
  .ms-horizontal-layout .ms-panel .navbar .navbar-nav .dropdown-toggle::after{
    -webkit-transform: rotate(95deg);
    transform: rotate(95deg);
    margin-top: 3px;
    margin-left: 10px;
  }
  .ms-panel.ms-panel-fh{
    height: auto;
  }

}

/* Footer */

/* Dropdowns */
.dropdown-menu{
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  visibility: hidden;
  top: 0;
  opacity: 0;
  -webkit-box-shadow: 0 1px 24px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 1px 24px rgba(0,0,0,0.1);
  box-shadow: 0 1px 24px rgba(0,0,0,0.1);
}
.dropdown-menu.show{
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.dropdown-menu-right{
  right: 0;
  left: auto;
}
.ms-dropdown-list{
  max-height: 18rem;
}
.dropdown-header{
  padding: .5rem;
}
.media{
  justify-content: space-between;
  align-items: center;
  -webkit-transition: 0s;
  transition: 0s;
}
.dropdown-menu .media:hover{
  background: #e9ecef;
}
.dropdown-toggle::after{
  vertical-align: 0.155em;
}
.user-dropdown.dropdown-menu{
  min-width: 200px;
}
.dropdown-menu .media img{
  width: 40px;
}
.dropdown-menu.dropright .dropdown-menu,
.dropdown-menu.dropleft .dropdown-menu{
  top: 0;
}
.dropdown-menu.dropleft li a:not(.dropdown-toggle){
  justify-content: flex-end;
}
@media (max-width: 768px){

  .ms-nav-list .dropdown{
    position: static;
  }
  .ms-navbar .dropdown-menu{
    width: auto;
    margin: 10px auto!important;
    right: 15px;
    left: 15px;
  }

}

/* Widgets */
.ms-widget.ms-has-new-msg .msg-count{
  top: -7px;
  right: 9px;
  left: auto;
}
.ms-notification-widget i{
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f0f0fa;
  color: #ff5757;
  margin-right: 1rem;
  font-size: 20px;
}
.PPC-alt{
  color: #3FA30C;
}
.ETH{
  color: #4e8ee8;
}
.ZEC-alt{
  color: #e5a93d;
}
.ADA-alt{
  color: #3CC8C8;
}
.EOS-alt{
  color: #19191A;
}
.ms-widget.ms-chat-conversations .ms-panel-footer{
  padding: 0;
}
.ms-widget.ms-chat-conversations.ms-chat-page .ms-panel-footer{
  padding: 1.5rem;
}
.ms-followers.ms-list .ms-list-item{
  padding: 1rem;
  margin-bottom: 0;
}
.ms-followers li{
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.ms-followers li:last-child{
  border-bottom: 0;
}
.ms-followers li h4{
  margin-bottom: 0;
}
.ms-followers li button{
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ms-followers .ms-list-item.media i{
  margin-right: 0;
}
.ms-profile-widget{
  text-align: center;
}
.ms-profile-widget .ms-user-img{
  position: absolute;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 2;
  border: 3px solid #ff5757;
}
.ms-card.ms-profile-widget .ms-card-img{
  position: relative;
  z-index: 1;
}
.ms-card.ms-profile-widget .ms-card-img::before{
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #ff57575c, #bc263494);
    z-index: 1;
}
.ms-card.ms-profile-widget .ms-card-body{
  padding-top: 65px;
}
.ms-profile-widget h2{
  margin-top: 1rem;
}
.ms-profile-widget button{
  margin: 0;
}
.ms-profile-widget span{
  display: block;
  margin-bottom: 1rem;
}
.ms-profile-widget .ms-profile-stats{
  padding: 1rem 0 0 0;
  border-bottom: 0;
  border-top: 1px solid rgba(0,0,0,.1);
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.ms-email-widget .form-group{
  position: relative;
}
.ms-email-widget .form-group input + .ms-cc{
  position: absolute;
  right: .75rem;
  top: 8px;
  color: #ff5757;
}
.ms-crypto-widget .table{
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.ms-infographics-widget .media-body p{
  margin-bottom: 0;
}
.ms-infographics-widget.has-graph canvas{
  position: absolute;
  top: 1rem;
  right: 0;
  height: 70% !important;
  width: 170px !important;
}
.ms-infographics-widget.has-graph i,
.ms-infographics-widget.has-graph-full-width i.cc{
  font-size: 40px;
  margin-right: 1rem;
}
.ms-infographics-widget.has-graph-full-width{
  position: relative;
}
.ms-infographics-widget.has-graph-full-width .ms-card-body{
  padding-bottom: 0;
}
.ms-infographics-widget.has-graph-full-width canvas{
  height: 60px !important;
  width: 100% !important;
}
.has-graph-full-width .ms-chart-label{
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  padding: 0.25rem 0.75rem;
}
.ms-infographics-widget.has-graph .ms-card-body + p{
  margin: 1rem;
  margin-top: 0;
  color: #3a3952;
  display: flex;
  justify-content: space-between;
}
.ms-infographics-widget.has-graph h6{
  margin-bottom: 0;
}
.ms-infographics-widget .ms-card-body h6{
  text-transform: uppercase;
}
.ms-card-change,
.ms-card-change i{
  font-size: 1.5rem;
}
.ms-infographics-widget .ms-card-body + i{
  position: absolute;
  top: 1rem;
  right: 3rem;
  color: rgba(255,255,255, 0.4);
}
.ms-infographics-widget .ms-card-body + i::before{
  font-size: 4rem;
}
.ms-identifier-widget.bg-primary{
  background-color: #ff5757;
}
.ms-identifier-widget.bg-secondary{
  background-color: #445cc8;
}
.ms-identifier-widget.bg-success{
  background-color: #5cb85c;
}
.ms-identifier-widget.bg-info{
  background-color: #5bc0de;
}
.ms-identifier-widget.bg-warning{
  background-color: #f0ad4e;
}
.ms-identifier-widget.bg-danger{
  background-color: #d9534f;
}
.ms-identifier-widget.bg-dark{
  background-color: #34334a;
}
.ms-identifier-widget.bg-light{
  background-color: #878793;
}
.ms-identifier-widget .ms-panel-header h6,
.ms-identifier-widget .ms-panel-body p,
.ms-identifier-widget .ms-panel-body i{
  color: #fff;
}
.ms-identifier-widget .ms-panel-body p{
  margin: 3rem 0;
}
.ms-identifier-widget .ms-panel-body i::before{
  margin-top: 2rem;
  display: block;
  font-size: 3rem;
}
.ms-identifier-widget .ms-panel-body{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Form Elements */

.ms-list-display li{
  margin-bottom: 1.5rem;
}

textarea{
  resize: none;
}
.ms-form-group{
  position: relative;
  display: block;
  margin: 20px 0;
  width: 100%;
}
.ms-form-group,
.ms-form-group::before,
.ms-form-group::after{
  content: '';
  display: table;
  clear: both;
}
.ms-form-group input.ms-form-input{
  padding: 11px 14px;
  border-radius: 25px;
  border: 0;
  background: #f0f0f7;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.ms-form-group input.ms-form-input:focus{
  outline: none;
  background: #dedeed;
}
.ms-form-group.has-icon i{
  position: absolute;
  top: 8px;
  right: 20px;
}
.ms-form-group.has-icon label + input + i{
  top: 38px;
  color: #878793;
  font-size: 18px;
}
.ms-form-group label{
  color: #3a3952;
  font-weight: 500;
  font-size: 13px;
}
.form-control{
  font-size: 14px;
  border: 1px solid rgba(0,0,0,0.1);
}
/* added css */
.new.form-control{
  width: 30%;
  height: 45px;
}
.ratings-new.ms-star-rating.rating-circle .ms-rating-item{
  border: none;
}

.ratings-new.ms-star-rating.rating-fill .ms-rating-item.rated,
.ratings-new.ms-star-rating.rating-fill .ms-rating-item:hover,
.ratings-new.ms-star-rating.rating-fill .ms-rating-item:hover ~ .ms-rating-item{
  background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: #ffc107;
    margin-right: 0;
}

.new{
  display: flex;
  justify-content: space-between;

  align-items: center;
}

/* added css end */

.input-group-text,
.custom-file-label::after{
  border: 0;
  font-size: 0.875rem;
  color: #878793;
  background-color: #ebebed;
}
.input-group{
  margin-bottom: 1rem;
}
.custom-file-label{
  color: #778087;
}
input[type="submit"]:disabled,
button[type="submit"]:disabled{
  background: #e4e3e8;
  border-color: #e4e3e8;
  cursor: not-allowed;
}
.input-group-prepend .btn,
.input-group-append .btn{
  margin-top: 0;
}

.switch-vertical{
    position: absolute;
    bottom: 14%;
    left: 16px;
    width: 29px;
    height: 61px;
    display: inline-block;

}

.ms-switch {

position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    margin-bottom: 5px;
    margin-right: 5px;

}
.switch-vertical .ms-switch{
  height: 61px;
  position: initial;
}
.ms-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ms-switch-slider.ms-switch-discount{
  position: absolute;
    cursor: pointer;
    top: -3px;
    left: 26%;
    width: 64px;
    height: 30px;
    right: 0;
    bottom: 0;
    background-color: #ff5757;
    -webkit-transition: .4s;
    transition: .4s;

}

.ms-switch-slider.ms-switch-discount:before{
  position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 37px;
    bottom: 5px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #fff;
}

input:checked + .ms-switch-slider.ms-switch-discount:before {
    -webkit-transform: translateX(-32px);
    -ms-transform: translateX(-32px);
    transform: translateX(-32px);
    background-color: #fff;
}

.ms-switch .ms-status-switch{
  position: absolute;
    cursor: pointer;
    top: 40px;
    left: 85%;
    width: 64px;
    height: 30px;
    right: 0;
    bottom: 0;
    background-color: #ff5757;
    -webkit-transition: .4s;
    transition: .4s;

}


.ms-switch-slider.ms-status-switch:before{
  position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 37px;
    bottom: 5px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #fff;
}

input:checked + .ms-switch-slider.ms-status-switch:before {
    -webkit-transform: translateX(-32px);
    -ms-transform: translateX(-32px);
    transform: translateX(-32px);
    background-color: #fff;
}


.ms-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4e3e8;
  -webkit-transition: .4s;
  transition: .4s;
}
.ms-switch-slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  background-color: #fff;
}
input:checked + .ms-switch-slider {
  background: #ff5757;
}
input:checked + .ms-switch-slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
  background-color: #fff;
}
.switch-vertical input:checked + .ms-switch-slider:before {
  -webkit-transform: translateY(-35px);
  -ms-transform: translateY(-35px);
  transform: translateY(-35px);
  background-color: #fff;
}
.ms-switch-slider.ms-switch-discount-2{
 position: absolute;
    cursor: pointer;
    top: 46%;
    width: 64px;
    height: 30px;
    left: 78%;
    right: 0;
    bottom: 0;
    background-color: #ff5757;
    -webkit-transition: .4s;
    transition: .4s;
}

.ms-switch-slider.ms-switch-discount-2:before {
  position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left:37px;
    bottom: 4px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #fff;
}

input:checked + .ms-switch-slider.ms-switch-discount-2:before {
    -webkit-transform: translateX(-32px);
    -ms-transform: translateX(-32px);
    transform: translateX(-32px);
    background-color: #fff;
}

input:checked + .ms-switch-slider.ms-switch-discount-3:before {
    -webkit-transform: translateX(-32px);
    -ms-transform: translateX(-32px);
    transform: translateX(-32px);
    background-color: #fff;
}
.ms-switch-slider.ms-switch-discount-3{
  position: absolute;
    cursor: pointer;
    top: 64%;
    width: 64px;
    height: 30px;
    left: 78%;
    right: 0;
    bottom: 0;
    background-color: #ff5757;
    -webkit-transition: .4s;
    transition: .4s;
}

.ms-switch-slider.ms-switch-discount-3:before{
  position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left:37px;
    bottom: 4px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #fff;
}

.ms-switch-slider.round {
  border-radius: 34px;
}
.ms-switch-slider.round:before {
  border-radius: 50%;
}
.ms-switch-slider.square {
  border-radius: 5px;
}
.ms-switch-slider.square:before {
  border-radius: 5px;
}
.ms-checkbox-wrap{
  position: relative;
  margin-bottom: 0;
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
.ms-checkbox-wrap input[type="checkbox"],
.ms-checkbox-wrap input[type="radio"]{
  position: absolute;
  top: 4px;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  width: 18px;
  height: 18px;
}
.ms-checkbox-wrap input[type="checkbox"] + i.ms-checkbox-check::after,
.ms-checkbox-wrap input[type="checkbox"] + i.ms-checkbox-check::before,
.ms-checkbox-wrap input[type="radio"] + i.ms-checkbox-check::after,
.ms-checkbox-wrap input[type="radio"] + i.ms-checkbox-check::before{
  position: absolute;
  top: 4px;
  left: 0
}
.ms-checkbox-wrap input[type="checkbox"] + i.ms-checkbox-check::before,
.ms-checkbox-wrap input[type="radio"] + i.ms-checkbox-check::before{
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #e0e0ef;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.ms-checkbox-wrap input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap input[type="radio"]:checked + i.ms-checkbox-check::before{
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #ff5757;
  background: #ff5757;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.ms-checkbox-wrap input[type="radio"] + i.ms-checkbox-check::before,
.ms-checkbox-wrap input[type="radio"]:checked + i.ms-checkbox-check::before{
  border-radius: 50%;
}
.ms-checkbox-wrap input[type="checkbox"] + i.ms-checkbox-check::after,
.ms-checkbox-wrap input[type="radio"] + i.ms-checkbox-check::after{
  content: "\e5ca";
  transition: all 0s;
  -webkit-transition: all 250ms;
  transition-duration: 250ms;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-weight: 700;
  opacity: 0;
  font-size: .5625rem;
  color: #fff;
  text-align: center;
  width: 18px;
  line-height: 18px;
}
.ms-checkbox-wrap input[type="checkbox"]:checked + i.ms-checkbox-check::after,
.ms-checkbox-wrap input[type="radio"]:checked + i.ms-checkbox-check::after{
  opacity: 1;
}
.ms-checkbox-wrap input[type="checkbox"]:disabled,
.ms-checkbox-wrap input[type="radio"]:disabled,
input:disabled + .ms-switch-slider{
  cursor: not-allowed;
}
.ms-checkbox-wrap input[type="checkbox"]:disabled + i.ms-checkbox-check::before,
.ms-checkbox-wrap input[type="radio"]:disabled + i.ms-checkbox-check::before,
input:disabled + .ms-switch-slider{
  background: #e9ecef;
  border-color: #e9ecef;
}
.ms-checkbox-wrap.ms-checkbox-primary input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-primary input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-primary{
  background: #ff5757;
  border-color: #ff5757;
}
.ms-checkbox-wrap.ms-checkbox-secondary input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-secondary input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-secondary{
  background: #374eae;
  border-color: #374eae;
}
.ms-checkbox-wrap.ms-checkbox-success input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-success input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-success{
  background: #07be6e;
  border-color: #07be6e;
}
.ms-checkbox-wrap.ms-checkbox-warning input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-warning input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-warning{
  background: #fda600;
  border-color: #fda600;
}
.ms-checkbox-wrap.ms-checkbox-danger input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-danger input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-danger{
  background: #f9423c;
  border-color: #f9423c;
}
.ms-checkbox-wrap.ms-checkbox-info input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-info input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-info{
  background: #4f8dca;
  border-color: #4f8dca;
}
.ms-checkbox-wrap.ms-checkbox-dark input[type="checkbox"]:checked + i.ms-checkbox-check::before,
.ms-checkbox-wrap.ms-checkbox-dark input[type="radio"]:checked + i.ms-checkbox-check::before,
input:checked + .ms-switch-slider.ms-switch-dark{
  background: #262538;
  border-color: #262538;
}
.ms-radio-img input[type="radio"]{
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.ms-payment-container{
  display: flex;
}
.ms-payment-container li{
  display: inline-block;
}
.ms-radio-img input[type="radio"] + .ms-payment-img{
  padding: 5px;
  margin-right: 5px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 80px;
}
.ms-radio-img input[type="radio"]:checked + .ms-payment-img{
  border-color: #ff5757;
}

/* Form Validation */
.ms-checkbox-wrap .invalid-feedback,
.ms-checkbox-wrap .valid-feedback{
  /* position: absolute; */
  /* top: 110%;
  min-width: 300px;
  left: 0; */
  text-align: left;
}
.invalid-feedback,
.valid-feedback{
  /* bottom: calc(100% + 10px);
  right: 0;
  text-align: right; */
  /* position: absolute; */
}
.ms-checkbox-wrap .invalid-tooltip,
.ms-checkbox-wrap .valid-tooltip{
  min-width: 300px;
  right: auto;
}
.invalid-tooltip,
.valid-tooltip{
  top: auto;
  bottom: calc( 100% + 7px );
  right: 0;
  left:auto;
}
.invalid-tooltip:before,
.valid-tooltip:before{
  content: '';
  position: absolute;
  top: 99%;
  right: 7px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 6px solid #ff5757;
}
.valid-tooltip{
  background-color: #09a561;
}
.custom-select.is-valid, .form-control.is-valid,
.was-validated .custom-select:valid, .was-validated .form-control:valid{
  border-color: #09a561;
}
.custom-select.is-invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid, .was-validated .form-control:invalid{
  border-color: #db3c36;
}
.validation-fill.custom-select.is-valid, .validation-fill .form-control.is-valid,
.validation-fill.was-validated .custom-select:valid, .validation-fill.was-validated .form-control:valid{
  background-color: #e0ffef;
  color: #09a561;
}
.validation-fill.custom-select.is-invalid, .validation-fill .form-control.is-invalid,
.validation-fill.was-validated .custom-select:invalid, .validation-fill.was-validated .form-control:invalid{
  background-color: #ffebea;
  color: #db3c36;
}
.invalid-feedback{
  color: #db3c36;
}
.valid-feedback{
  color: #09a561;
}
.invalid-tooltip{
  background-color: #db3c36;
}
.invalid-tooltip:before{
  border-top-color: #db3c36;
}
.valid-tooltip:before{
  border-top-color: #09a561;
}
/* Form Wizard */
.ms-form-wizard{
  background-color: #fff;
}
.ms-form-wizard .actions ul{
  margin: 2rem 0;
}
.ms-form-wizard .actions ul,
.ms-form-wizard .actions ul::after,
.ms-form-wizard .actions ul::before{
  content: '';
  display: block;
  clear: both;
}
.ms-form-wizard ul[role="tablist"]{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.ms-form-wizard ul[role="tablist"] li{
  position: relative;
  background: #e4e3e8;
  margin-right: 10px;
  flex: 1 1 auto;
  text-align: center;
}
.ms-form-wizard ul[role="tablist"] li:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ms-form-wizard ul[role="tablist"] li::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-top: 20px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 20px solid transparent;
}
.ms-form-wizard ul[role="tablist"] li::after{
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  z-index: 2;
  height: 100%;
  border-top: 20px solid transparent;
  border-left: 20px solid #e4e3e8;
  border-bottom: 20px solid transparent;
}
.ms-form-wizard ul[role="tablist"] li:first-child::before{
  display: none;
}
.ms-form-wizard ul[role="tablist"] li a{
  -webkit-transition: 0s;
  transition: 0s;
  padding: 10px 0;
  display: block;
}
.ms-form-wizard ul[role="tablist"] li.go2998626915::after,
.ms-form-wizard ul[role="tablist"] li.current::after{
  border-left-color: #ff5757;
}
.ms-form-wizard ul[role="tablist"] li.go2998626915,
.ms-form-wizard ul[role="tablist"] li.current{
  background-color: #ff5757;
}
.ms-form-wizard ul[role="tablist"] li.done::after{
  border-left-color: #ff5757;
}
.ms-form-wizard ul[role="tablist"] li.done{
  background-color: #ff5757;
}
.ms-form-wizard ul[role="tablist"] li a,
.ms-form-wizard ul[role="tablist"] li a:hover{
  color: #95959e;
}
.ms-form-wizard ul[role="tablist"] li.go3309388127 span,
.ms-form-wizard ul[role="tablist"] li.go2998626915 a,
.ms-form-wizard ul[role="tablist"] li.go2998626915 span,
.ms-form-wizard ul[role="tablist"] li.current a,
.ms-form-wizard ul[role="tablist"] li.done a,
.ms-form-wizard ul[role="tablist"] li.current span,
.ms-form-wizard ul[role="tablist"] li.done span{
  color: #fff;
}
.ms-form-wizard > .content > .title{
  position: absolute;
  left: -1000px;
}
.ms-form-wizard > .content{
  min-height: 18rem;
}
.ms-form-wizard .actions ul li:first-child{
  float: left;
}
.ms-form-wizard .actions ul li:nth-child(2),
.ms-form-wizard .actions ul li:last-child{
  float: right;
}
.ms-form-wizard .actions ul li a{
  font-size: 14px;
  outline: none;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.625rem 1rem;
  min-width: 120px;
  margin-top: 1rem;
  border-radius: 5px;
  color: #fff;
}
.ms-form-wizard .actions ul li:not(.disabled) a{
  background-color: #ff5757;
  border-color: #ff5757;
}
.ms-form-wizard .actions ul li.disabled a{
  background-color: #e4e3e8;
  border-color: #e4e3e8;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li{
  margin-right: 0;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li a{
  position: relative;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li a .number,
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li a .number,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li:last-child a::after{
  position: absolute;
  top: 0;
  left: -20px;
  height: 100%;
  width: 40px;
  background-color: #ff5757;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 24px rgba(0,0,0,0.1);
  box-shadow: 0 0 24px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li:first-child a .number{
  left: 0;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li:last-child a::after{
  content: '\e5ca';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  right: 0;
  left: auto;
  background: #fff;
  color: #ff5757;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li.current:last-child a::after,
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li.done:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.current:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.done:last-child a::after{
  background-color: #ff5757;
  color: #fff;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li.disabled a .number{
  background-color: #fff;
  color: #ff5757;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li:first-child{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li:last-child{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li::before,
.ms-wizard-pill.ms-form-wizard ul[role="tablist"] li::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li::before,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li::after,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li::before,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li::after{
  display: none;
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"]{
  margin-top: 1rem;
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li{
  height: 4px;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li a .number,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li:last-child a::after{
  top: -20px;
  left: 0;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
  border: 4px solid rgba(0,0,0,0.1);
  color: #95959e;
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.go2998626915:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.done:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.current:last-child a::after{
  background-color: #fff;
  border-color: #ff5757;
  color: #ff5757;
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.go2998626915 a .number,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.go2998626915:last-child a::after,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.current a .number,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.done a .number,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.done:last-child a::after{
  border-color: #ff5757;
  color: #ff5757;
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li:last-child a::after{
  left:auto;
  right: 0;
}
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.go2998626915,
.ms-wizard-round.ms-form-wizard ul[role="tablist"] li.current{
  background-color: transparent;
  background-image: linear-gradient(to right, #ff5757, #bc2634);
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"]{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 250px;
  height: 100%;;
  background-color: #ff5757;
  padding: 1rem;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li{
  position: relative;
  border-radius: 25px;
  background-color: transparent;
  margin-bottom: 2rem;
  flex: 0 0 auto;
  width: 100%;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li a::after{
  content: '';
  position: absolute;
  top: 90%;
  left: 18px;
  width: 5px;
  height: 160%;
  background-color: #fff;
  z-index: -1;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li:last-child a::after{
  display: none;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li a{
  position: relative;
  z-index: 3;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li a .number{
  position: absolute;
  top: 2px;
  left: 3px;
  border-radius: 50%;
  height: 90%;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #ff5757;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li a{
  color: #fff;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.go2998626915,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.current,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.done{
  background-color: #fff;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.go2998626915 a .number,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.current a .number,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.done a .number{
  background-color: #ff5757;
  color: #fff;
}
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.go2998626915 a,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.current a,
.ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li.done a{
  color: #ff5757;
}
.ms-wizard-vertical.ms-form-wizard .content,
.ms-wizard-vertical.ms-form-wizard .actions{
  margin-left: 250px;
}

/* Accordions */
.accordion .card {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}
.accordion .card-header{
  position: relative;
  background-color: #fff;
  border-bottom: 0;
  cursor: pointer;
}
.accordion .card .collapseparent {
  order: 2;
}
.accordion .card .card-header {
  width: 100%;
  flex-basis: 100%;
  order: 1;
}
.accordion .card .collapseparent.show+.card-header,
.accordion .card-header[aria-expanded="true"]{
  background-color: #f0f1f6;
}
.accordion .card-header::after{
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 700;
  position: absolute;
  top: .75rem;
  right: 1.25rem;
}
.accordion .card .collapseparent.show+.card-header:after,
.accordion .card-header[aria-expanded="true"]::after{
  content: '\e15b';
}
.accordion .card .card-header:after,
.accordion .card-header[aria-expanded="false"]::after{
  content: '\e145';
}
.ms-accordion-chevron.accordion .card .collapseparent+.card-header:after,
.ms-accordion-chevron.accordion .card-header::after{
  transition: 0.3s;
  content: '\e313';
}
.ms-accordion-chevron.accordion .card .collapseparent.show+.card-header:after,
.ms-accordion-chevron.accordion .card-header[aria-expanded="true"]::after{
  transform: rotate(180deg);
}
.ms-accordion-chevron.accordion .card .collapseparent+.card-header:after,
.ms-accordion-chevron.accordion .card-header[aria-expanded="false"]::after{
  transform: rotate(0deg);
}
.accordion .card-header span{
  display: block;
  font-size: 14px;
  max-width: 87%;
  color: #3a3952;
  font-weight: 600;
}
.accordion .card-header span.has-icon{
  margin-left: 40px;
}
.accordion .card-header i{
  position: absolute;
  top: 4px;
  left: 15px;
}
.accordion .card-header i::before{
  font-size: 24px;
}
.accordion .card{
  border: 1px solid rgba(0,0,0,.1);
}
.accordion .card-body{
  color: #878793;
}
.accordion.has-gap .card-header{
  border-radius: 3px 3px;
}
.accordion.has-gap .card:first-of-type,
.accordion.has-gap .card:not(:first-of-type):not(:last-of-type){
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.accordion.has-gap .card{
  margin-bottom: 1rem;
}

/* Alerts */
.alert.alert-success{
  background-color: #e0ffef;
  color: #07be6e;
  border-color: #07be6e;
}
.alert.alert-info{
  background-color: #e0f2ff;
  color: #4f8dca;
  border-color: #4f8dca;
}
.alert.alert-warning{
  background-color: #fef6e3;
  color: #fda600;
  border-color: #fda600;
}
.alert.alert-danger{
  background-color: #ffebea;
  color: #f9423c;
  border-color: #f9423c;
}
.alert.alert-brand{
  background-color: #eaedff;
  color: #374eae;
  border-color: #374eae;
}
.alert.alert-outline{
  background: transparent;
}


.ms-dark-theme .alert.alert-outline{
  background: #ffffff;
}

.alert.alert-solid{
  color: #fff;
}
.alert.alert-solid.alert-success{
  background-color: #07be6e;
}
.alert.alert-solid.alert-info{
  background-color: #4f8dca;
}
.alert.alert-solid.alert-warning{
  background-color: #fda600;
}
.alert.alert-solid.alert-danger{
  background-color: #f9423c;
}
.alert.alert-solid.alert-brand{
  background-color: #374eae;
}
.alert i{
  margin-right: 15px;
}

/* Breadcrumbs */

.breadcrumb{
  align-items: center;
  border-radius: 0;
  background-color: #f0f0fa;
}
.breadcrumb.breadcrumb-arrow-sep .breadcrumb-item + .breadcrumb-item::before{
  content: '\e315';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
}
.breadcrumb-dark{
  background-color: #ff5757;
}
.breadcrumb-dark a,
.breadcrumb-dark .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-dark .breadcrumb-item.active{
  color: #fff;
}
.breadcrumb.breadcrumb-arrow{
  overflow: hidden;
}
.breadcrumb.breadcrumb-arrow li{
  position: relative;
  background: #fff;
  padding: 10px 40px;
}
.breadcrumb.breadcrumb-arrow li::after{
  content: '';
  position: absolute;
  top: 0;
  right: -20px;
  z-index: 2;
  height: 100%;
  border-top: 20px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 20px solid transparent;
}
.breadcrumb.breadcrumb-arrow li::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-top: 20px solid transparent;
  border-left: 20px solid #f0f0fa;
  border-bottom: 20px solid transparent;
}
.breadcrumb.breadcrumb-arrow.has-bg li:not(.active),
.breadcrumb.breadcrumb-arrow.has-bg-active li{
  background-color: #ff5757;
}
.breadcrumb.breadcrumb-arrow.has-bg li:not(.active) a,
.breadcrumb.breadcrumb-arrow.has-bg-active li a,
.breadcrumb.breadcrumb-arrow.has-bg-active .breadcrumb-item.active{
  color: #fff;
}
.breadcrumb.breadcrumb-arrow.has-bg li:not(.active)::after,
.breadcrumb.breadcrumb-arrow.has-bg-active li::after{
  border-left-color: #ff5757;
}
.breadcrumb.breadcrumb-arrow.has-gap li{
  margin-right: 10px;
}
.breadcrumb.breadcrumb-arrow:not(.has-gap) li:not(:first-child)::after,
.breadcrumb.breadcrumb-arrow:not(.has-gap) li:not(:first-child)::before{
  display: none;
}
.breadcrumb.breadcrumb-arrow:not(.has-gap) li:last-child::after{
  display: block;
}
.breadcrumb.breadcrumb-arrow.first-rounded li:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.breadcrumb.breadcrumb-arrow.first-rounded li:first-child::before{
  display: none;
}
.breadcrumb.has-shadow li{
  -webkit-box-shadow: 0px 3px 16px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 16px rgba(0,0,0,0.2);
}

/* Cards */
.ms-card{
  background-color: #fff;
  -webkit-box-shadow: 0 1px 6px 1px rgba(69,65,78, 0.1);
  -moz-box-shadow: 0 1px 6px 1px rgba(69,65,78,0.1);
  box-shadow: 0 1px 6px 1px rgba(69,65,78,0.1);
  margin-bottom: 2rem;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.1);
}
[class*='card-']{
  border: 0;
}
[class*='card-'] .ms-card-body,
[class*='card-']:not(.ms-card-fh) .ms-card-body p,
[class*='card-']:not(.ms-card-fh) .ms-card-body h6,
[class*='card-gradient-'] .ms-card-body,
[class*='card-gradient-']:not(.ms-card-fh) .ms-card-body p,
[class*='card-gradient-']:not(.ms-card-fh) .ms-card-body h6{
  color: #fff;
}
.ms-card.card-primary,
.ms-dark-theme .ms-card.card-primary{
  background-color: #ff5757;
}
.ms-card.card-secondary,
.ms-dark-theme .ms-card.card-secondary{
  background-color: #374eae;
}
.ms-card.card-success,
.ms-dark-theme .ms-card.card-success{
  background-color: #07be6e;
}
.ms-card.card-warning,
.ms-dark-theme .ms-card.card-warning{
  background-color: #fda600;
}
.ms-card.card-danger,
.ms-dark-theme .ms-card.card-danger{
  background-color: #f9423c;
}
.ms-card.card-info,
.ms-dark-theme .ms-card.card-info{
  background-color: #4f8dca;
}
.ms-card.card-dark,
.ms-dark-theme .ms-card.card-dark{
  background-color: #262538;
}
.ms-card.card-gradient-primary{
  background-color: #ff5757;
      background-image: linear-gradient(to right, #ff5757, #bc2634);
}
.ms-card.card-gradient-secondary{
  background-color: #000000;
    background-image: linear-gradient(to right, #8f8f8fa6, #bc26341c);


}
.ms-card.card-gradient-success{
background-color: #d9534f;
    background-image: linear-gradient(to right, #fe051d, #fd162b);
}
.ms-card.card-gradient-warning{
  background-color: #fea837;
  background-image: linear-gradient(to right, #fea837, #fed257);
}
.ms-card.card-gradient-danger{
  background-color: #eb025d;
  background-image: linear-gradient(to right, #eb025d, #fe4749);
}
.ms-card.card-gradient-info{
  background-color: #3e60ff;
  background-image: linear-gradient(to right, #3e60ff,#5ba0fd);
}
.ms-card.card-gradient-light{
  background-color: #5f5f67;
  background-image: linear-gradient(to right, #5f5f67, #858591);
}
.ms-card.card-gradient-dark{
  background-color: #252434;
  background-image: linear-gradient(to right, #252434, #3a3850);
}
.ms-card.card-facebook,
.ms-dark-theme .ms-card.card-facebook{
  background-color: #3b5997;
}
.ms-card.card-twitter,
.ms-dark-theme .ms-card.card-twitter{
  background-color: #3da4f1;
}
.ms-card.card-linkedin,
.ms-dark-theme .ms-card.card-linkedin{
  background-color: #0177b5;
}
.ms-card.card-instagram,
.ms-dark-theme .ms-card.card-instagram{
  background: linear-gradient(to top right, #fa7e1e, #d82a74, #9a2ebc, #4f5bd5);
}
.ms-card.ms-card-fh{
  height: calc( 100% - 2rem );
}
.ms-card-header h6{
  margin-bottom: 0;
  display: inline-block;
}
.ms-card-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ms-card-header .ms-btn-icon{
  width: 28px;
  height: 28px;
}
.ms-card-body,
.ms-card-footer{
  position: relative;
  padding: 1rem;
  font-size: 14px;
}
.ms-card-body h6{
  margin-bottom: 1rem;
}

.ms-dark-theme .ms-card-body p{
  color: #ffffff;
}
.ms-card-body p{
  color: #878793;
}
.ms-card-footer{
  border-top: 1px solid rgba(0,0,0,0.1);
}
.ms-card .ms-card-img img{
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.ms-card .ms-card-body + .ms-card-img img{
  border-radius: 0;
}
.ms-card .ms-card-body > .media{
  margin-bottom: 1rem;
}
.ms-card .ms-card-body > .media h6{
  display: inline-block;
  margin-bottom: 0;
}
.ms-card .ms-card-body > .media img{
  width: 50px;
}
.ms-card .ms-card-body > .media i{
  font-size: 18px;
}
.ms-card .ms-card-options{
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.1);
}
.ms-card .ms-card-options i{
  margin-right: 5px;
}
.ms-card .ms-card-options:last-child{
  border-right: 0;
}

/* Progress Bars */

.progress{
  margin-bottom: 1.5rem;
  border-radius: 3rem;
  height: 0.9rem;
}
.progress.progress-tiny{
  height:0.2rem;
}
.progress-status{
  float: right;
}
.progress-label,
.progress-status{
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}
.progress-rounded{
  position: relative;
  margin: 0 auto;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 16px solid #f1f1f1;
  margin-bottom: 2rem;
}
.progress-rounded *{
  transition: all .3s ease;
}
.progress-rounded .progress-value{
  font-size: 1rem;
  font-weight: 600;
  color: #34334a;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.progress-rounded svg{
  height: 130px;
  width: 130px;
  position: absolute;
  top: -16px;
  left: -16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}
.progress-rounded svg circle{
  stroke-dasharray: 358.141563 358.141563;
  stroke-dashoffset: 360;
  stroke: #ff5757;
  stroke-linecap: square;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}
.progress-rounded.progress-round-tiny{
  width: 120px;
  height: 120px;
  border-width: 4px;
}
.progress-rounded.progress-round-tiny svg{
  top: -9px;
  left: -9px;
}

.progress-rounded svg circle.bg-primary{
  stroke: #ff5757;
}
.progress-rounded svg circle.bg-secondary{
  stroke: #445cc8;
}
.progress-rounded svg circle.bg-success{
  stroke: #5cb85c;
}
.progress-rounded svg circle.bg-info{
  stroke: #5bc0de;
}
.progress-rounded svg circle.bg-warning{
  stroke: #f0ad4e;
}
.progress-rounded svg circle.bg-danger{
  stroke: #d9534f;
}
.progress-rounded svg circle.bg-dark{
  stroke: #34334a;
}
.progress-rounded svg circle.bg-light{
  stroke: #878793;
}

/* Buttons */

/* new-s */
.btn-outline-primary:not(:disabled):not(.disabled).active{
  color: #fff;
      background-color: #ff5757;
      border-color: #ff5757;
}
.ms-dark-theme .btn-outline-primary:not(:disabled):not(.disabled).active{
  color: #ff5757;
      background-color: #ffffff;
      border-color: #ffffff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #bc2634;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #bc2634;
}

.wrapper-new1 {
    display: flex;
    justify-content: space-between;

}
.wrapper-new2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.box .badge{
  line-height: 1.2;
  font-size: 100%
}

/* end */
.ms-btn-display button,
.ms-btn-display .badge{
  margin-right: 1.5rem;
  margin-top: 1.5rem;
}
.ms-btn-display button .badge{
  margin: 0;
}
.btn{
  font-size: 14px;
  outline: none;
  padding: 0.625rem 1rem;
  min-width: 120px;
  margin-top: 1rem;
}
.btn:hover,
.ms-dark-theme .btn:hover{
  color: #fff;
}
.btn-link{
  color: #ff5757;
}
.btn.btn-square,
.ms-btn-icon.btn-square,
.ms-btn-icon-outline.btn-square{
  border-radius: 0;
}
.btn.btn-pill,
.ms-btn-icon.btn-pill,
.ms-btn-icon-outline.btn-pill{
  border-radius: 25px;
}
.btn.has-icon i{
  margin-right: 5px;
}
.btn-primary{
  color: #fff;
  background-color: #ff5757;
  border-color: #ff5757;
}


.ms-dark-theme .btn-primary{
  color: #ff5757;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-white{
  font-weight: 500;
}
.btn-white, .btn-white:hover,
.ms-dark-theme .btn-white{
  background-color: #fff;
  border-color: #fff;
  color: #ff5757;
}
.btn-primary:hover,
.btn-primary:focus,
.ms-btn-icon-outline.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:hover{
  background-color: #bc2634;
  border-color: #bc2634;
  color: #fff
}
.btn-secondary{
  color: #fff;
  background-color: #000000de;
    border-color: #000000de;
}
.btn-secondary:hover,
.btn-secondary:focus,
.ms-btn-icon-outline.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:hover{
  color: #fff;
  background-color: #000000;
    border-color: #000000;
}
.btn-success{
  color: #fff;
  background-color: #07be6e;
  border-color: #07be6e;
}
.btn-success:hover,
.btn-success:focus,
.ms-btn-icon-outline.btn-success:focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-outline-success:hover{
  color: #fff;
  background-color: #09a561;
  border-color: #09a561;
}
.btn-info{
  color: #fff;
  background-color: #4f8dca;
  border-color: #4f8dca;
}
.btn-info:hover,
.btn-info:focus,
.ms-btn-icon-outline.btn-info:focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-outline-info:hover{
  color: #fff;
  background-color: #437eb7;
  border-color: #437eb7;
}
.btn-danger{
  color: #fff;
  background-color: #f9423c;
  border-color: #f9423c;
}
.btn-danger:hover,
.btn-danger:focus,
.ms-btn-icon-outline.btn-danger:focus,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:hover{
  color: #fff;
  background-color: #db3c36;
  border-color: #db3c36;
}
.btn-warning{
  color: #fff;
  background-color: #fda600;
  border-color: #fda600;
}
.btn-warning:hover,
.btn-warning:focus,
.ms-btn-icon-outline.btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:hover{
  color: #fff;
  background-color: #e89800;
  border-color: #e89800;
}
.btn-dark{
  color: #fff;
  background-color: #34334a;
  border-color: #34334a;
}
.btn-dark:hover,
.btn-dark:focus,
.ms-btn-icon-outline.btn-dark:focus,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:hover{
  color: #fff;
  background-color: #262538;
  border-color: #262538;
}
.btn-light{
  color: #fff;
  background-color: #878793;
  border-color: #878793;
}
.btn-light:hover,
.btn-light:focus,
.ms-btn-icon-outline.btn-light:focus,
.btn-light:not(:disabled):not(.disabled):active,
.btn-outline-light:hover{
  color: #fff;
  background-color: #75757c;
  border-color: #75757c;
}
.ms-btn-icon,
.ms-btn-icon-outline{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 38px;
  height: 38px;
  border-radius: .25rem;
  line-height: 1.5;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: pointer;
}

.ms-btn-icon i{
  color: #fff;
  font-size: 16px;
}
.ms-btn-icon-outline{
  background-color: transparent;
  border: 1px solid;
}
.ms-btn-icon-outline[class*='btn-']:hover{
  color: #fff;
}

/* hover outline */

 .btn-outline-primary:not(:disabled):not(.disabled):active{
   background-color: #bc2634;
   color: #fff;
 }
.ms-btn-icon-outline.btn-primary,
.btn-outline-primary{
  color: #ff5757;
  border-color: #ff5757;
}
 .ms-dark-theme .btn-outline-primary:not(:disabled):not(.disabled):active{
   background-color: #ffffff;
   color:  #bc2634;
   border-color: #ffffff;
 }
.ms-dark-theme .ms-btn-icon-outline.btn-primary,
.ms-dark-theme .btn-outline-primary{
  color: #ffffff;
  border-color: #ffffff;
}
.ms-btn-icon-outline.btn-secondary,
.btn-outline-secondary{
  color: #374eae;
  border-color: #374eae;
}
.ms-btn-icon-outline.btn-success,
.btn-outline-success{
  color: #07be6e;
  border-color: #07be6e;
}
.ms-btn-icon-outline.btn-info,
.btn-outline-info{
  color: #4f8dca;
  border-color: #4f8dca;
}
.ms-btn-icon-outline.btn-warning,
.btn-outline-warning{
  color: #fda600;
  border-color: #fda600;
}
.ms-btn-icon-outline.btn-danger,
.btn-outline-danger{
  color: #f9423c;
  border-color: #f9423c;
}
.ms-btn-icon-outline.btn-dark,
.btn-outline-dark{
  color: #34334a;
  border-color: #34334a;
}
.ms-btn-icon-outline.btn-light,
.btn-outline-light{
  color: #878793;
  border-color: #878793;
}
[class*='btn-gradient-']{
  color: #fff;
  border: 0;
}
.btn-gradient-primary{
  background-color: #ff5757;
    background-image: linear-gradient(to right, #ff5757, #bc2634);
}
.btn-gradient-secondary{
  background-color: #926afe;
  background-image: linear-gradient(to right, #926afe, #bd9aff);
}
.btn-gradient-success{
  background-color: #12b252;
  background-image: linear-gradient(to right, #12b252, #31e97a);
}
.btn-gradient-info{
  background-color: #3e60ff;
  background-image: linear-gradient(to right, #3e60ff,#5ba0fd);
}
.btn-gradient-warning{
  background-color: #fea837;
  background-image: linear-gradient(to right, #fea837, #fed257);
}
.btn-gradient-danger{
  background-color: #eb025d;
  background-image: linear-gradient(to right, #eb025d, #fe4749);
}
.btn-gradient-dark{
  background-color: #252434;
  background-image: linear-gradient(to right, #252434, #3a3850);
}
.btn-gradient-light{
  background-color: #5f5f67;
  background-image: linear-gradient(to right, #5f5f67, #858591);
}
.btn-group.flex-column label{
  margin-top: 0;
  border-radius: 0;
}
.btn-group.flex-column > .btn:first-child, .btn-group.flex-column > .btn:first-child:not(.dropdown-toggle){
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.btn-group.flex-column > .btn:last-child, .btn-group.flex-column > .btn:last-child:not(.dropdown-toggle){
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
.btn-group-sm>.btn, .btn-sm{
  line-height: 0.5;
}
.btn.bg-facebook, .btn.bg-twitter, .btn.bg-linkedin,
.btn.bg-g-plus, .btn.bg-instagram, .btn.bg-pinterest,
.btn.bg-dropbox, .btn.bg-dribble{
  border: 0;
  color: #fff;
}

/* Badges */
[class*='badge-'],
a.badge[class*='badge-']:hover{
  color: #fff;
}
.badge.badge-primary{
  background-color: #ff5757;
}
.badge.badge-secondary{
  background-color: #445cc8;
}
.badge.badge-success{
  background-color:  #28a745;
}
.badge.badge-warning{
  background-color: #fda600;
}
.badge.badge-danger{
  background-color: #f9423c;
}
.badge.badge-info{
  background-color:#ff5757;
}
.badge.badge-light{
  background-color: #878793;
}
.badge.badge-dark{
  background-color: #34334a;
}
a.badge.badge-primary:hover{
  background-color: #bc2634;
}
a.badge.badge-secondary:hover{
  background-color: #374eae;
}
a.badge.badge-success:hover{
  background-color: #09a561;
}
a.badge.badge-warning:hover{
  background-color: #e89800;
}
a.badge.badge-danger:hover{
  background-color: #db3c36;
}
a.badge.badge-info:hover{
  background-color: #437eb7;
}
a.badge.badge-light:hover{
  background-color: #75757c;
}
a.badge.badge-dark:hover{
  background-color: #262538;
}
.badge[class*='badge-outline-']{
  background-color: transparent;
  border: 1px solid;
}
button .badge[class*='badge-outline-'],
a .badge[class*='badge-outline-']{
  background-color: #fff;
}
.badge.badge-outline-primary{
  border-color: #ff5757;
  color: #ff5757;
}
.badge.badge-outline-secondary{
  border-color: #374eae;
  color: #374eae;
}
.badge.badge-outline-success{
  border-color: #07be6e;
  color: #07be6e;
}
.badge.badge-outline-warning{
  border-color: #fda600;
  color: #fda600;
}
.badge.badge-outline-danger{
  border-color: #f9423c;
  color: #f9423c;
}
.badge.badge-outline-info{
  border-color: #4f8dca;
  color: #4f8dca;
}
.badge.badge-outline-light{
  border-color: #878793;
  color: #878793;
}
.badge.badge-outline-dark{
  border-color: #262538;
  color: #262538;
}
.badge-gradient-primary{
  background-color: #ff5757;
  background-image: linear-gradient(to right, #ff5757, #bc2634);
}
.badge-gradient-secondary{
  background-color: #926afe;
  background-image: linear-gradient(to right, #926afe, #bd9aff);
}
.badge-gradient-success{
  background-color: #12b252;
  background-image: linear-gradient(to right, #12b252, #31e97a);
}
.badge-gradient-info{
  background-color: #3e60ff;
  background-image: linear-gradient(to right, #3e60ff,#5ba0fd);
}
.badge-gradient-warning{
  background-color: #fea837;
  background-image: linear-gradient(to right, #fea837, #fed257);
}
.badge-gradient-danger{
  background-color: #eb025d;
  background-image: linear-gradient(to right, #eb025d, #fe4749);
}
.badge-gradient-dark{
  background-color: #252434;
  background-image: linear-gradient(to right, #252434, #3a3850);
}
.badge-gradient-light{
  background-color: #5f5f67;
  background-image: linear-gradient(to right, #5f5f67, #858591);
}
.badge-square{
  border-radius: 0;
}

/* Pagination */
.pagination.has-gap .page-item .page-link{
  margin-right: 0.425rem;
}
.page-item:not(.active) .page-link,
.page-item:not(.active-circle) .page-link,
.page-item:not(.active-bordered) .page-link,
.page-item:not(.active-round) .page-link{
  color: #34334a;
}
.pagination-light .page-item:not(.active) .page-link,
.pagination-light .page-item:not(.active-circle) .page-link,
.pagination-light .page-item:not(.active-round) .page-link{
  background-color: #eeeff7;
  border-color: #eeeff7;
}
.pagination-round .page-item .page-link{
  border-radius: 5px;
}
.pagination-circle .page-item .page-link{
  border-radius: 50%;
}
.pagination .page-link:hover{
  z-index: 0;
}
.page-item.active .page-link,
.pagination-light .page-item.active .page-link{
  color: #fff;
  background-color: #ff5757;
  border-color: #ff5757;
  z-index: 0;
}
.page-item.active-circle .page-link,
.page-item.active-round .page-link{
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
}
.page-item.active-circle .page-link::after,
.page-item.active-round .page-link::after{
  content: '';
  position: absolute;
  top: 0;
  left: -3px;
  width: calc(100% + 0.4rem);
  height: 100%;
  z-index: -1;
  background-color: #ff5757;
  border-radius: 25px;
  transform: scale(1.2);
}
.page-item.active-round .page-link::after{
  border-radius: 5px;
}
.page-item.active-bordered .page-link{
  border-bottom: 2px solid #ff5757;
  color: #ff5757;
}
.pagination-pill .page-item:first-child .page-link{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.pagination-pill .page-item:last-child .page-link{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.pagination-input input[type="number"]{
  width: 51px;
  border: 1px solid #dee2e6;
  height: 100%;
  text-align: center;
  padding: .3rem 0.1rem;
  outline: none;
}
.pagination.pagination-expanded{
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  background-color: #fff;
}
.pagination.pagination-expanded.has-shadow{
  border: 0;
  -webkit-box-shadow: 0 2px 24px rgba(0,0,0,.1);
  box-shadow: 0 2px 24px rgba(0,0,0,.1);
}
.pagination-expanded .page-item .page-link{
  position: relative;
  border: 0;
  margin: 0 4px;
  z-index: 1;
}
.pagination-expanded .page-item.active .page-link{
  background-color: transparent;
  border-color: transparent;
}
.pagination-expanded .page-item-expanded input[type="number"]{
  width: 51px;
  border: 1px solid #dee2e6;
  height: 100%;
  text-align: center;
  padding: .5rem 0.2rem;
  outline: none;
  border-radius: 5px;
}
.pagination-expanded .page-item-expanded .go-to-page{
  font-size: 12px;
  margin: 0 10px;
  display: inline-block;
}
.pagination-expanded .page-item .page-link:hover{
  background-color: transparent;
}
.pagination-expanded .page-item.active .page-link::after,
.pagination-expanded .page-item .page-link:hover::after,
.pagination-expanded .page-item:first-child .page-link::after,
.pagination-expanded .page-item:last-child .page-link::after{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  margin-left: -40%;
  margin-top: -40%;
  z-index: -1;
  background-color: #ff5757;
  transform: rotate(45deg);
  border-radius: 5px;
}
.pagination-expanded .page-item:first-child .page-link::after,
.pagination-expanded .page-item:last-child .page-link::after{
  background-color: #fff;
  border-color: #fff;
}
.pagination-expanded .page-item:not(.active) .page-link:hover::after{
  background-color: #e9ecef;
}

/* Preloaders */
#preloader-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20009;
  background-color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#preloader-wrap.loaded{
  visibility: hidden;
  opacity: 0;
}
.spinner-1{
  width: 40px;
  height: 40px;
  background-color: #ff5757;
  margin: 100px auto;
  -webkit-animation: ms-rotateplane 1.2s infinite ease-in-out;
  animation: ms-rotateplane 1.2s infinite ease-in-out;
}

.ms-dark-theme .spinner-1{
  background-color: #ffffff;
}


.spinner-2 {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}


.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff5757;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: ms-bounce 2.0s infinite ease-in-out;
  animation: ms-bounce 2.0s infinite ease-in-out;
}


.ms-dark-theme .double-bounce1, .double-bounce2{
  background-color: #ffffff;
}
.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}


.spinner-3 {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.spinner-3 > div {
  background-color: #ff5757;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: ms-stretchdelay 1.2s infinite ease-in-out;
  animation: ms-stretchdelay 1.2s infinite ease-in-out;
  margin-left: 2px;
}

.ms-dark-theme .spinner-3 > div {
  background-color: #ffffff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: ms-stretchdelay 1.2s infinite ease-in-out;
  animation: ms-stretchdelay 1.2s infinite ease-in-out;
}


.spinner-3 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner-3 .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner-3 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner-3 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.spinner-4{
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.cube1, .cube2 {
  background-color: #ff5757;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: ms-cubemove 1.8s infinite ease-in-out;
  animation: ms-cubemove 1.8s infinite ease-in-out;
}

.ms-dark-theme .cube1,
.ms-dark-theme .cube2 {
  background-color: #ffffff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: ms-cubemove 1.8s infinite ease-in-out;
  animation: ms-cubemove 1.8s infinite ease-in-out;
}
.ms-dark-theme .cube2,
.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner-5{
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #ff5757;
  border-radius: 100%;
  -webkit-animation: ms-scaleout 1.0s infinite ease-in-out;
  animation: ms-scaleout 1.0s infinite ease-in-out;
}

.ms-dark-theme .spinner-5{
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-animation: ms-scaleout 1.0s infinite ease-in-out;
  animation: ms-scaleout 1.0s infinite ease-in-out;
}
.spinner-6{
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  -webkit-animation: ms-rotate 2.0s infinite linear;
  animation: ms-rotate 2.0s infinite linear;
}
.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #ff5757;
  border-radius: 100%;
  -webkit-animation: ms-bounce 2.0s infinite ease-in-out;
  animation: ms-bounce 2.0s infinite ease-in-out;
}


.ms-dark-theme .dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-animation: ms-bounce 2.0s infinite ease-in-out;
  animation: ms-bounce 2.0s infinite ease-in-out;
}


.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner-7{
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}
.spinner-7 > div {
  width: 18px;
  height: 18px;
  background-color: #ff5757;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: ms-bouncedelay 1.4s infinite ease-in-out both;
  animation: ms-bouncedelay 1.4s infinite ease-in-out both;
}

.ms-dark-theme .spinner-7 > div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: ms-bouncedelay 1.4s infinite ease-in-out both;
  animation: ms-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner-7 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner-7 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.spinner-8 {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.spinner-8 .ms-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.spinner-8 .ms-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ff5757;
  border-radius: 100%;
  -webkit-animation: ms-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: ms-circleBounceDelay 1.2s infinite ease-in-out both;
}

.ms-dark-theme .spinner-8 .ms-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-animation: ms-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: ms-circleBounceDelay 1.2s infinite ease-in-out both;
}


.spinner-8 .ms-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.spinner-8 .ms-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.spinner-8 .ms-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.spinner-8 .ms-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.spinner-8 .ms-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.spinner-8 .ms-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.spinner-8 .ms-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.spinner-8 .ms-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.spinner-8 .ms-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.spinner-8 .ms-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.spinner-8 .ms-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.spinner-8 .ms-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.spinner-8 .ms-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.spinner-8 .ms-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.spinner-8 .ms-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.spinner-8 .ms-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.spinner-8 .ms-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.spinner-8 .ms-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.spinner-8 .ms-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.spinner-8 .ms-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.spinner-8 .ms-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.spinner-8 .ms-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

.spinner-9 {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}
.spinner-9 .ms-cube {
  width: 33%;
  height: 33%;
  background-color: #ff5757;
  float: left;
  -webkit-animation: ms-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: ms-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.ms-dark-theme .spinner-9 .ms-cube {
  width: 33%;
  height: 33%;
  background-color: #ffffff;
  float: left;
  -webkit-animation: ms-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: ms-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.spinner-9 .ms-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.spinner-9 .ms-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.spinner-9 .ms-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.spinner-9 .ms-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.spinner-9 .ms-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.spinner-9 .ms-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.spinner-9 .ms-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.spinner-9 .ms-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.spinner-9 .ms-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.spinner-10{
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.spinner-10,
.spinner-12{
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.spinner-10 .ms-cube,
.spinner-12 .ms-cube{
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.spinner-10 .ms-cube:before,
.spinner-12 .ms-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff5757;
  -webkit-animation: ms-foldCubeAngle 2.4s infinite linear both;
          animation: ms-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

.ms-dark-theme .spinner-10 .ms-cube:before,
.ms-dark-theme .spinner-12 .ms-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  -webkit-animation: ms-foldCubeAngle 2.4s infinite linear both;
          animation: ms-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.spinner-10 .ms-cube2,
.spinner-12 .ms-cube2{
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.spinner-10 .ms-cube3,
.spinner-12 .ms-cube3{
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.spinner-10 .ms-cube4,
.spinner-12 .ms-cube4{
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.spinner-10 .ms-cube2:before,
.spinner-12 .ms-cube2:before{
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.spinner-10 .ms-cube3:before,
.spinner-12 .ms-cube3:before{
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.spinner-10 .ms-cube4:before,
.spinner-12 .ms-cube4:before{
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.spinner-11 {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.spinner-11 .ms-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.spinner-11 .ms-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ff5757;
  border-radius: 100%;
  -webkit-animation: ms-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: ms-circleFadeDelay 1.2s infinite ease-in-out both;
}

.ms-dark-theme .spinner-11 .ms-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-animation: ms-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: ms-circleFadeDelay 1.2s infinite ease-in-out both;
}
.spinner-11 .ms-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.spinner-11 .ms-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.spinner-11 .ms-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.spinner-11 .ms-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.spinner-11 .ms-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.spinner-11 .ms-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.spinner-11 .ms-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.spinner-11 .ms-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.spinner-11 .ms-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.spinner-11 .ms-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg);
}
.spinner-11 .ms-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg);
}
.spinner-11 .ms-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.spinner-11 .ms-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.spinner-11 .ms-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.spinner-11 .ms-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.spinner-11 .ms-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.spinner-11 .ms-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.spinner-11 .ms-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.spinner-11 .ms-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.spinner-11 .ms-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.spinner-11 .ms-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.spinner-11 .ms-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

/* Tabs */
.nav-tabs{
border: 0;
}
.nav-tabs .nav-item{
  flex: 1 1 auto;
}
.nav-tabs .nav-item .nav-link{
  display: block;
  padding: 10px;
  text-align: center;
  background: #e4e3e8;
  color: #34334a;
  border-radius: 0;
  border: none;
}
.nav-tabs .nav-item .nav-link.white{
  display: block;
  padding: 10px;
  text-align: center;
  background: #ffffff;
  color: #34334a;
}
.nav-tabs .nav-item .nav-link.active{
  color: #fff;
  background-color: #ff5757;
}
.nav-tabs.tabs-bordered .nav-item .nav-link{
  -webkit-transition: 0s;
  transition: 0s;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  background: transparent;
}
.nav-tabs.tabs-bordered .nav-item .nav-link.active{
  background: #fff;
  color: #ff5757;
  border-bottom: 2px solid #ff5757;
}
.tabs-bordered li a{
  background: #fff;
  padding: 0 0 1rem;
}
.nav-tabs.left-tabs,
.nav-tabs.right-tabs{
  -webkit-flex-direction: column;
  flex-direction: column;
}
.nav-tabs.left-tabs{
  float:left;
  margin-right: 25px;
}
.nav-tabs.right-tabs{
  margin-left: 25px;
  float: right;
}
.nav-tabs.left-tabs .nav-item .nav-link,
.nav-tabs.right-tabs .nav-item .nav-link{
  padding: 5px 10px;
  margin-bottom: 10px;
}
.nav-tabs.left-tabs .nav-item .nav-link{
  text-align: left;
}
.nav-tabs.right-tabs .nav-item .nav-link{
  text-align: right;
}
.nav-tabs.tabs-bordered.left-tabs .nav-item .nav-link,
.nav-tabs.tabs-bordered.right-tabs .nav-item .nav-link{
  border-bottom: 0;
}
.nav-tabs.tabs-bordered.left-tabs .nav-item .nav-link.active{
  border-bottom: 0;
  border-left: 3px solid #ff5757;
}
.nav-tabs.tabs-bordered.right-tabs .nav-item .nav-link.active{
  border-bottom: 0;
  border-right: 3px solid #ff5757;
}
.nav-tabs.left-tabs + .tab-content{
  float: right;
}
.nav-tabs.right-tabs + .tab-content{
  float: left;
}
.nav-tabs.left-tabs + .tab-content,
.nav-tabs.right-tabs + .tab-content{
  width: calc(100% - 120px);
}
.nav-tabs.tab-pills .nav-item .nav-link{
  border-radius: 25px;
}
.nav-tabs.tabs-round .nav-item .nav-link{
  border-radius: 5px;
}
.nav-tabs.has-gap .nav-item .nav-link{
  margin-right: 15px;
}
.nav-tabs.has-gap .nav-item:last-child .nav-link{
  margin-right: 0;
}
.ms-panel-header .nav.btn-group{
  flex-wrap: nowrap;
}
 
/* Tooltips */
.tooltip{
	padding: 5px;
}
.tooltip.show{
	opacity: 1 !important;
}
.tooltip > .tooltip-inner{
	-webkit-box-shadow: 0px 1px 5px 0px rgba( 0, 0, 0, 0.1 );
	-moz-box-shadow: 0px 1px 5px 0px rgba( 0, 0, 0, 0.1 );
	box-shadow: 0px 1px 15px rgba( 0, 0, 0, 0.1 );
	border:rgba(0, 0, 0, 0.12) solid 1px;
	background-color: #eeeff7;
	opacity: 1;
	font-size: 14px;
	color: #555b62;
}
.tooltip .arrow:before{
  display: none;
}
/* Lists */
.ms-list{
  margin-bottom: 0;
}
.ms-list .ms-list-item{
  position: relative;
  padding: 0.75rem;
  margin-bottom: 1rem;
}
.ms-list-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ms-list .ms-list-item:last-child{
  margin-bottom: 0;
}
.ms-task-block li.ms-to-do-task:first-child{
  padding-top: 0;
}
.ms-list .ms-list-item.bordered{
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
  background: #fff;
}
.ms-list-item p{
  margin-bottom: 0;
  font-size: 12px;
}
.ms-list-item.media i{
  font-size: 20px;
  margin-right:25px;
}
.ms-list-item.media img{
  width: 40px;
  margin-right: 1rem;
}

/* Draggables */
.ui-sortable:not(.ui-sortable-disabled):hover,
.ms-draggable:hover{
  cursor: grab;
}
.ui-sortable:not(.ui-sortable-disabled):active,
.ms-draggable:active{
  cursor: grabbing;
}
.ui-state-highlight{
  margin-bottom: 1rem;
}
.ms-quickbar-container .ui-state-highlight{
  margin: 0 1rem 1rem 1rem;
  border-radius: 5px;
}
.ms-quick-bar-item.ui-draggable-dragging{
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
}
.ms-quick-bar-item.ui-draggable-dragging,
.ms-list-item.ui-draggable-dragging{
  z-index: 999;
  -webkit-box-shadow: 0 3px 24px rgba(0,0,0,0.4);
  box-shadow: 0 3px 24px rgba(0,0,0,0.4);
}
.ui-sortable-placeholder{
  background: #e4e3e8;
}
.ms-sortable-list{
  min-height: 100px;
}

/* Sliders */

.carousel-control-next, .carousel-control-prev{
  height: 60px;
  width: auto;
  background: rgba(0,0,0,0.4);
  padding: 0 5px;
  top: 50%;
  margin-top: -30px;
  opacity: 1;
}
.carousel-control-next:hover, .carousel-control-prev:hover{
  background: rgba(0,0,0,0.8);
}
.carousel-control-next span, .carousel-control-prev span{
  color: #fff;
}
.carousel-caption{
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0,0,0,0.6), rgba(255,255,255,0));
}
.carousel-caption h5,
.carousel-caption p{
  color: #fff;
}
.ms-button-slider .carousel-control-next, .ms-button-slider .carousel-control-prev{
  position: relative;
  height: auto;
  width: 60px;
  margin: 1rem 0.5rem 0 0.5rem;
  display: inline-block;
  padding: 5px;
  background: #878793;
  border-radius: 5px;
}
.ms-button-slider .carousel-control-next:after{
  content: 'Next';
}
.ms-button-slider .carousel-control-prev:after{
  content: 'Prev';
}
.ms-button-slider .carousel-control-next>span,
.ms-button-slider .carousel-control-prev>span{
  display: none;
}
.ms-button-slider .carousel-control-next:hover, .ms-button-slider .carousel-control-prev:hover{
  background: #75757c;
}
.ms-carousel-buttons{
  text-align: center;
}
.ms-indicator-slider .carousel-indicators{
  position: relative;
  margin: 2rem 15% 1rem 15%;
  bottom: auto;
}
.ms-indicator-slider.carousel{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.ms-indicator-slider.carousel .carousel-indicators{
  order: 2;
}
.ms-indicator-slider.carousel .carousel-inner{
  order: 1;
}
.ms-indicator-slider .carousel-indicators li{
  background: #445cc8;
  border:none
}
.ms-indicator-slider .carousel-indicators li:not(.active){
  opacity: 0.6;
  width: 20px;
}
.ms-dotted-indicator-slider .carousel-indicators li{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.ms-dotted-indicator-slider .carousel-indicators li:not(.active){
  background: transparent;
  border: 1px solid #fff;
}
.ms-image-slider .carousel-indicators{
  margin: 2% 0 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  padding: 1rem 0;
}
.ms-image-slider .carousel-indicators li{
  width: 100px;
  height: 70px;
}
.ms-image-slider .carousel-indicators li:before{
  width: 100%;
  height: 100%;
  content: '';
  background-image: url('../img/costic/add-product-1.jpg');
    display: block;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.ms-image-slider .carousel-indicators li:nth-child(2):before{
  background-image: url('../img/costic/add-product-2.jpg');
}
.ms-image-slider .carousel-indicators li:nth-child(3):before{
  background-image: url('../img/costic/add-product-3.jpg');
}
.ms-image-slider .carousel-indicators li.active:before{
  border: 1px solid #fff;
}
.ms-image-slider .carousel-indicators li:not(.active):before{
  opacity: 0.7;
}
.ms-swipe-slider .carousel-inner{
  display: flex;
}
.ms-swipe-slider .carousel-item{
  display: block;
  flex: 0 1 auto;
  margin: 0 1rem 0 0;
}
.ms-swipe-slider .carousel-item:last-child{
  margin: 0;
}

/* Modals */
.modal-content{
  border: 0;
  -webkit-box-shadow: 0 1px 24px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 1px 24px rgba(0,0,0,0.1);
  box-shadow: 0 1px 24px rgba(0,0,0,0.1);
}
.modal-open .modal{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.close,
.close:focus{
  outline: none;
  opacity: 1;
  text-shadow: none;
}
.close span{
  text-shadow: none;
}
.modal-header.bg-secondary .close span{
  color: #fff;
}
.modal-title.has-icon i{
  margin-right: 10px;
}
.modal-title.has-icon.ms-icon-round i{
  width: 35px;
  height: 35px;
  vertical-align: middle;
  border-radius: 50%;
  padding-top: 5px;
  margin-right: 15px;
  display: inline-block;
  text-align: center;
}
.modal-notice{
  flex: 1 1 auto;
}
.modal-notice a{
  color: #878793;
}
.modal-body p:last-child{
  margin: 0;
}
.modal-min .modal-body{
  padding: 3rem 2rem;
}
.modal-min .modal-body .close{
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.modal-min .modal-body i::before{
  font-size: 60px;
  color: #ff5757;
}
.modal-min .ms-form-group.has-icon i,
.modal-max .ms-form-group.has-icon i{
  top: 10px;
  color: #878793;
  font-size: 18px;
}
.modal-max .ms-list .ms-list-item{
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.modal-max .ms-list-item i::before{
  font-size: 30px;
}

/* Rating */
.table td .ms-star-rating{
  margin-bottom: 0;;
}
.ms-star-rating{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.ms-star-rating .ms-rating-item{
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 4px 0;
  margin-right: 10px;
  border: 1px solid #878793;
  color: #878793;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.ms-star-rating .item-1{
  margin-right: -11px;
}

.ms-star-rating .item-2{
  margin-left: -7px;
}



.ms-star-rating .ms-rating-item i{
  font-size: 16px;
}
.ms-star-rating.rating-circle .ms-rating-item{
  border-radius: 50%;
}
.ms-star-rating.rating-fill .ms-rating-item.rated,
.ms-star-rating.rating-fill .ms-rating-item:hover,
.ms-star-rating.rating-fill .ms-rating-item:hover ~ .ms-rating-item{
  background-color: #ff5757;
  border-color: #ff5757;
  color: #fff;
}
.ms-star-rating.rating-bordered .ms-rating-item.rated,
.ms-star-rating.rating-bordered .ms-rating-item:hover,
.ms-star-rating.rating-bordered .ms-rating-item:hover ~ .ms-rating-item{
  border: 1px solid #ff5757;
  color: #ff5757;
}
.ms-star-rating.rating-fill.heart-rating .ms-rating-item.rated,
.ms-star-rating.rating-fill.heart-rating .ms-rating-item:hover,
.ms-star-rating.rating-fill.heart-rating .ms-rating-item:hover ~ .ms-rating-item{
  background-color: #ff5757;
  border-color: #ff5757;
  color: #fff;
}
.ms-star-rating.rating-bordered.heart-rating .ms-rating-item.rated,
.ms-star-rating.rating-bordered.heart-rating .ms-rating-item:hover,
.ms-star-rating.rating-bordered.heart-rating .ms-rating-item:hover ~ .ms-rating-item{
  border: 1px solid #ff5757;
  color: #ff5757;
}
.ms-star-rating.misc-number .ms-rating-item{
  margin: 0;
  border-radius: 0;
  padding: 3px 0;
  width: 40px;
  border-color: #ff5757;
  border-left: 1px solid rgba(0,0,0,0.08);
  border-right: 0;
}
.ms-star-rating.misc-number .ms-rating-item i{
  font-size: 14px;
  font-style: normal;
}
.ms-star-rating.misc-number .ms-rating-item.rated,
.ms-star-rating.misc-number .ms-rating-item:hover,
.ms-star-rating.misc-number .ms-rating-item:hover ~ .ms-rating-item{
  background: #ff5757;
  color: #fff;
}
.ms-star-rating.misc-number .ms-rating-item:first-child{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-right: 1px solid #ff5757;
}
.ms-star-rating.misc-number .ms-rating-item:last-child{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-left: 1px solid #ff5757;
}
.ms-star-rating.letter-rating .ms-rating-item{
  padding: 2px 0;
}
.ms-star-rating.letter-rating .ms-rating-item i{
  text-transform: uppercase;
  font-style: normal;
}
.ms-star-rating.misc-checks .ms-rating-item:not(.rated)::before{
  content: '\e5cd';
  font-family: 'Material Icons';
  font-weight: 700;
  font-style: normal;
}
.ms-star-rating.misc-checks .ms-rating-item.rated::before,
.ms-star-rating.misc-checks .ms-rating-item:hover::before,
.ms-star-rating.misc-checks .ms-rating-item:hover ~ .ms-rating-item::before{
  content: '\e5ca';
  font-family: 'Material Icons';
  font-weight: 700;
  font-style: normal;
}
.ms-star-rating.misc-blocks .ms-rating-item,
.ms-star-rating.misc-square .ms-rating-item{
  width: 50px;
  height: 3px;
  border-radius: 0;
  border: 0;
  background-color: #ff5757;
  opacity: 0.3;
}
.ms-star-rating.misc-square .ms-rating-item{
  width: 20px;
  height: 30px;
}
.ms-star-rating.misc-blocks .ms-rating-item.rated,
.ms-star-rating.misc-blocks .ms-rating-item:hover,
.ms-star-rating.misc-blocks .ms-rating-item:hover ~ .ms-rating-item,
.ms-star-rating.misc-square .ms-rating-item.rated,
.ms-star-rating.misc-square .ms-rating-item:hover,
.ms-star-rating.misc-square .ms-rating-item:hover ~ .ms-rating-item{
  opacity: 1;
}

/* Tour */

div#driver-popover-item .driver-popover-footer button{
  background-color: #ff5757;
  border-color: #ff5757;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  text-shadow: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
div#driver-popover-item .driver-popover-footer button:hover{
  background-color: #ff5757;
}
div#driver-popover-item .driver-popover-footer button.driver-disabled,
div#driver-popover-item .driver-popover-footer .driver-close-btn{
  background-color: #878793;
  border-color: #878793;
  color: #fff;
}
div#driver-popover-item .driver-popover-footer button.driver-disabled:hover,
div#driver-popover-item .driver-popover-footer .driver-close-btn:hover{
  background-color: #75757c;
}

/* Croppable */

.ms-crop-preview{
  overflow: hidden;
  margin-bottom: 2rem;
}
.ms-cropper-container{
  margin-bottom: 2rem;
}
.ms-crop-preview.ms-crop-preview-lg{
  max-width: 100%;
  height: 300px;
}
.ms-crop-preview.ms-crop-preview-md{
  max-width: 70%;
  height: 200px;
}
.ms-crop-preview.ms-crop-preview-sm{
  max-width: 40%;
  height: 150px;
}
.ms-cropper-controls{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ms-cropper-controls button{
  margin-right: 1rem;
  margin-bottom: 1rem;
}

/* Range Sliders */
.irs--flat .irs-bar,
.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single,
.irs--flat .irs-handle>i:first-child{
  background-color: #ff5757;
}
.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before{
  border-top-color: #ff5757;
}

/* Animations */
.ms-animate{
  max-width: 565px;
  margin: 0 auto;
}
.ms-animate img{
  margin-bottom: 2rem;
}

/* Icons */
.ms-icons-list{
  border-left: 1px solid rgba(0,0,0,0.1);
  border-top: 1px solid rgba(0,0,0,0.1);
  margin: 0;
}
.ms-icons-list > div{
  border-bottom: 1px solid rgba(0,0,0,0.1);
  border-right: 1px solid rgba(0,0,0,0.1);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 15px;
}
.ms-icons-list > div > i{
  display: inline-block;
  font-size: 20px;
  width: 40px;
  text-align: left;
  color: #3a3952;
}
.ms-icons-list.flaticons > div > i::before{
  font-size: 24px;
}

/* Basic Tables */
.thead-primary thead{
  background-color: #ff5757;
}
.thead-secondary thead{
  background-color: #445cc8;
}
.thead-success thead{
  background-color: #5cb85c;
}
.thead-info thead{
  background-color: #5bc0de;
}
.thead-warning thead{
  background-color: #f0ad4e;
}
.thead-danger thead{
  background-color: #d9534f;
}
.thead-dark thead{
  background-color: #34334a;
}
.thead-light thead{
  background-color: rgb(244,244,244);
}
table img{
  max-width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.ms-table-f-w{
  min-width: 185px;
}
table img + span{
  margin-left: 15px;
  font-weight: 400;
  color: #212529;
}
table i{
  margin-right: 10px;
  font-size: 18px;
}
.table thead tr th{
  border-top: 0;
}
.thead-dark thead th, .thead-primary thead th, .thead-secondary thead th,
.thead-success thead th, .thead-info thead th, .thead-warning thead th,
.thead-danger thead th{
  color: #fff;
}
.table thead th{
  border-bottom: 1px solid #dee2e6;
}


/* Data tables */
.dataTables_info{
  color: #878793;
}
.dataTables_length label select{
  margin-left: 10px;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before{
  content: '\e5c7';
  font-family: 'Material Icons';
  font-weight: 700;
  font-style: normal;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after{
  content: '\e5c5';
  font-family: 'Material Icons';
  font-weight: 700;
  font-style: normal;
  right: 1em;
  bottom: 0.4em;
}

.dataTables_filter label input{
  border-radius: 25px;
  padding: 11px 14px;
}

/* Maps */
.map-container{
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: block;
  padding: 35% 0;
  overflow: hidden;
  border-radius: 5px;
}
.map-container .google-map{
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.vector-map .jvectormap-container>svg,
.vector-map{
  position: relative;
  width: 100%;
  height: 330px;
}
.vector-map .jvectormap-container{
  height: 100%;
}
.jvectormap-zoomin,
.jvectormap-zoomout{
  display: none;
}
/* Default Login and Signup */
.ms-content-wrapper.ms-auth{
  padding: 0;
  background-color: #fff;
  height: 100vh;
}
.ms-auth-container{
  display: flex;
}
.ms-auth-col{
  flex: 1 1 auto;
  height: 100vh;
}
.ms-auth-bg{
  position: relative;
  height: 100%;
      background-image: url('../img/costic/default-login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.ms-auth-form{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 3rem 1rem;
}
.ms-auth-form form{
  width: 80%;
}
.ms-auth-form .btn-social-login span{
  width: 150px;
  display: inline-block;
  text-align: left;
}
.ms-auth-form .btn-social-login img{
  width: 20px;
  margin-right: 15px;
}
.ms-auth-form .btn-social-login{
  background-color: #f6f6f6;
}

/* Modal Login and Signup */
.modal-auth.modal-dialog{
  max-width: 95%;
}
.modal-auth .modal-content{
  height: auto;
}
.modal-auth .modal-body{
  padding: 0;
  overflow: hidden;
}
.modal-auth .modal-content{
  border-radius: 0;
}
.modal-auth .close{
  position: absolute;
  top: -30px;
  right: -30px;
  z-index: 2;
  background-color: #3a3952;
  opacity: 1;
  width: 70px;
  height: 70px;
  border-bottom-left-radius: 50%;
}
.modal-auth .ms-auth-col:first-child{
  flex: 1 1 0;
}
.modal-auth .close span{
  position: absolute;
  bottom: 13px;
  left: 20px;
  color: #fff;
}
.modal-auth .ms-auth-col{
  height: auto;
}
.modal-auth .ms-auth-form{
  padding: 3rem 1rem;
}
.modal-auth .ms-auth-bg{
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-auth .ms-auth-bg::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 127, 250, 0.8);
  z-index: -1;
}
.ms-auth-slider{
  text-align: center;
  width: 50%;
}
.ms-auth-slider h1{
  margin-bottom: 1rem;
  font-size: 28px;
}
.ms-auth-slider h1,
.ms-auth-slider p{
  color: #fff;
}
.modal-auth .ms-indicator-slider .carousel-indicators li{
  background: #fff;
}

/* Lock screen */
.ms-lock-screen{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../img/costic/lock-screen.jpg');
  background-size: cover;
  background-position: center center;
  z-index: 2;
}
.ms-lock-screen-user{
  width: 150px;
  margin-bottom: 2rem;
}
.ms-lock-screen .ms-content-wrapper{
  text-align: center;
  width: 500px;
}
.ms-lock-screen .ms-content-wrapper h1{
  color: #fff;
  margin-bottom: 2rem;
}
.ms-lock-screen input.ms-form-input{
  background-color: transparent !important;
  border: 1px solid #fff;
  color: #fff !important;
  border-radius: 5px;
  width: 100%;
}
.ms-lock-screen input.ms-form-input:focus{
  background: transparent;
}
.ms-lock-screen input.ms-form-input + i{
  color: #fff;
  top: 15px;
  font-size: 16px;
}
.ms-lock-screen input.ms-form-input::placeholder {
  color: #fff !important;
  opacity: 1;
}
.ms-lock-screen input.ms-form-input:-ms-input-placeholder {
  color: #fff !important;
}
.ms-lock-screen-nav{
  display: flex;
  justify-content: space-around;
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 150px;
  background-color: rgba(255,255,255,0.3);
  z-index: 20;
  padding: 1rem 0;
  border-radius: 25px;
}
.ms-lock-screen-nav li.ms-nav-item{
  padding: 0 1rem;
}
.ms-lock-screen-nav .ms-nav-item > a i{
  color: #fff;
  font-size: 20px;
}
.ms-lock-screen-nav .dropdown-menu{
  width: 300px;
}
.ms-lock-screen-weather{
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 3;
}
.ms-lock-screen-weather p{
  color: #fff;
  font-size: 1.3rem;
}
.ms-lock-screen-weather p:first-child{
  font-size: 4rem;
}
.ms-lock-screen-time{
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 2;
}
.ms-lock-screen-time p{
  color: #fff;
  font-size: 1.3rem;
}
.ms-lock-screen-time p:first-child{
  font-size: 6rem;
  line-height: 5rem;
}

/* Error 404 */
.ms-error-404{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../img/costic/lock-screen.jpg');
  background-size: cover;
  background-position: center center;
  z-index: 2;
}
.ms-error-404::before,
.ms-lock-screen::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff00009e;
  z-index: -1;
}
.ms-error-404 .ms-content-wrapper{
  text-align: center;
}
.ms-error-404 .ms-content-wrapper i::before{
  font-size: 8rem;
}
.ms-error-404 .ms-content-wrapper a i{
  margin-right: 5px;
}
.ms-error-404 .ms-content-wrapper h3{
  font-size: 24px;
  font-weight: 400;
}
.ms-error-404 .ms-content-wrapper h1,
.ms-error-404 .ms-content-wrapper h3,
.ms-error-404 .ms-content-wrapper p,
.ms-error-404 .ms-content-wrapper i::before{
  color: #fff;
  margin-bottom: 1rem;
}

/* Coming Soon */
.ms-coming-soon{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../img/costic/coming-soon.jpg');
  background-size: cover;
  background-position: center center;
}
.ms-coming-soon .ms-content-wrapper{
  text-align: center;
  width: 500px;
}
.ms-coming-soon h1{
  font-size: 44px;
  margin-bottom: 2rem;
  color: #3a3952;
}
.ms-coming-soon p{
  color: #3a3952;
  margin-bottom: 2rem;
}
.ms-dark-theme .ms-coming-soon p.ms-duration, .ms-dark-theme .ms-coming-soon h1{
  color: #3a3952;
}
.ms-coming-soon button{
  padding: 0.225rem 1rem;
}
.ms-coming-soon ul{
  margin: 2rem 0;
}
.ms-coming-soon ul li p{
  margin-bottom: 0;
  font-weight: 700;
}
.ms-coming-soon ul li p:first-child{
  font-size: 26px;
}
.ms-coming-soon .input-group{
  margin-bottom: 2rem;
}
.ms-coming-soon ul li a.ms-social{
  font-size: 18px;
}
.ms-coming-soon ul.ms-social-container{
  justify-content: space-around;
}
a.ms-fb,
.ms-dark-theme a.ms-fb{
  color: #3b5797;
}

.nav-tabs.white .nav-item .nav-link{
  background-color: #fff;
}

.ms-dark-theme .ms-card-body span.white{
  background:#ffffff;
    border-radius: 19%;
    padding: 1px 5px;
    color: #ff5757;
}

.ms-card-body span.white{
  background: #ff5757;
  border-radius: 19%;
  padding: 7px 7px;
  color: #fff;
  font-weight: 600;
}
a.ms-pnt,
.ms-dark-theme a.ms-pnt{
  color: #bd081b;
}
a.ms-tw,
.ms-dark-theme a.ms-tw{
  color: #3da4f3;
}
a.ms-wa,
.ms-dark-theme a.ms-wa{
  color: #29928B;
}
a.ms-skype,
.ms-dark-theme a.ms-skype{
  color: #0991fa;
}
a.ms-g-plus,
.ms-dark-theme a.ms-g-plus{
  color: #db4a39;
}
.ms-dark-theme .ms-coming-soon .form-control{
  background-color: #fff;
  color: #495057;
}
.ms-dark-theme .ms-coming-soon input.form-control::placeholder{
  color: #495057;
  opacity: 1;
}
.ms-dark-theme .ms-coming-soon input.form-control::-ms-input-placeholder{
  color: #495057;
}

/* User Profile */

.ms-profile-overview{
  position: relative;
  height: 500px;
  background-color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 6px 1px rgba(69,65,78, 0.1);
  -moz-box-shadow: 0 1px 6px 1px rgba(69,65,78,0.1);
  box-shadow: 0 1px 6px 1px rgba(69,65,78,0.1);
}
.ms-profile-cover{
  position: relative;
  display: flex;
  align-items: flex-end;
  background-image: url('../img/costic/banner-1000x370.jpg');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 87%;
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 0;
  z-index:1;
}
.ms-profile-cover::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to top, rgba(0,0,0,0.4), rgba(255,255,255,0));
  z-index: -1;
}
.ms-profile-cover img{
  width: 200px;
  margin-bottom: -35px;
  border: 3px solid #fff;
  border-radius: 5px;
}
.ms-profile-user-info,
.ms-profile-user-buttons{
  padding: 0 0 1.5rem 1.5rem;
}
.ms-profile-user-buttons{
  flex: 1 1 auto;
  text-align: right;
}
.ms-profile-user-buttons a+a{
  margin-left: 10px;
}
.ms-profile-user-buttons button i{
  margin-right: 5px;
}
.ms-profile-user-info h1,
.ms-profile-user-info h2{
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}
.ms-profile-user-info h2{
  font-size: 12px;
  font-weight: 400;
}
.ms-profile-skills{
  border-top: 1px solid rgba(0,0,0,.1);
  padding: 1.5rem 0 0;
}
.ms-skill-list{
  margin-bottom: 0;
}
.ms-skill-list .ms-skill{
  display: inline-block;
  border: 1px solid rgba(0,0,0,.1);
  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  text-align: center;
  min-width: 100px;
  font-weight: 500;
}
.ms-profile-information tr td{
  text-align: right;
}
.ms-profile-stats{
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 0 0 1rem 0;
  margin-bottom: 1.5rem;
}
.ms-profile-stats li{
  flex: 1;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,.1);
}
.ms-profile-stats li:last-child{
  border-right: 0;
}
.ms-profile-stats li span{
  display: block;
}
li .ms-count{
  font-size: 24px;
  font-weight: 700;
}
.ms-profile-navigation{
  position: absolute;
  bottom: 0;
  left: 250px;
}
.ms-profile-navigation .nav-item{
  flex: 0 0 auto;
}
.ms-profile-navigation .nav-item .nav-link{
  padding: 1rem;
}
.ms-profile-navigation.tabs-bordered .nav-item .nav-link{
  border-bottom: 0;
  color: #878793;
}
.ms-profile-experience{
  margin-bottom: 1.5rem;
}
.ms-profile-experience p{
  font-size: 12px;
  margin-bottom: 0;
  line-height: 1.5rem;
}
.ms-profile-experience h4{
  margin-bottom: 0;
  font-size: 16px;
}

/* Portfolio */
.card-columns .card{
  margin-bottom: 1.5rem;
  border: 0;
}
.ms-portfolio-item{
  position: relative;
  overflow: hidden;
}
.ms-portfolio-item::before{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, rgba(0,0,0,.4), rgba(255,255,255,0));
  z-index: 1;
}
.ms-portfolio img,
.ms-portfolio .card{
  border-radius: 10px;
}
.ms-portfolio-item-content{
  position: absolute;
  width:100%;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 2;
}
.ms-portfolio-item-content h4,
.ms-portfolio-item-content .item-content-inner span,
.ms-portfolio-minimal .ms-portfolio-item-content p{
  color: #fff;
}
.item-content-inner{
  display: flex;
  margin-top: 0.5rem;
}
.ms-portfolio-item-content .item-content-inner span{
  margin-right: 2rem;
}
.ms-portfolio-item-content .item-content-inner span i{
  font-size: 16px;
}
.ms-portfolio-minimal .ms-portfolio-item-content{
  text-align: center;
}
.ms-portfolio-minimal .ms-portfolio-item-content p{
  margin-bottom: 0;
}
.ms-portfolio-minimal .ms-portfolio-item-content{
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.ms-portfolio-minimal .ms-portfolio-item::before{
  height: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.ms-portfolio-minimal .ms-portfolio-item:hover::before{
  height: 100%;
}
.ms-portfolio-minimal .ms-portfolio-item:hover .ms-portfolio-item-content{
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/* Incvoice */

.invoice-address h3{
  font-size: 30px;
  margin-bottom: 1rem;
}
.invoice-address h5{
  font-size: 16px;
  margin-bottom: 0.75rem;
}

/* Chat */

.ms-chat-status{
  position: relative;
}
.ms-chat-status::after{
  content: '';
  position: absolute;
  bottom: 0;
  right:0;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ms-status-online::after{
  background: #0ac282;
}
.ms-status-offline::after{
  background: #aeaeae;
}
.ms-status-busy::after{
  background: #f04747;
}
.ms-status-away::after{
  background: #faa61a;
}
.ms-chat-header .ms-chat-img,
.ms-chat-container .ms-chat-img,
.ms-chat-message .ms-chat-img{
  width: 40px;
}
.ms-widget .ms-chat-header{
  display: flex;
}
.ms-chat-container .ms-chat-header{
  display: block;
}
.ms-chat-user-info{
  position: relative;
}
.ms-chat-user-info a{
  color: #878793;
}
.ms-chat-user-info h6{
  margin-bottom: 0;
}
.ms-chat-conversations .ms-chat-user-info h6{
  text-transform: capitalize;
}
.ms-chat-conversations .ms-panel-body{
  max-height: 100vh;
}
.ms-chat-user-info .has-chevron::after{
  top: 0;
  right: -15px;
}
.ms-open-chat{
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ms-open-chat .ms-chat-user-info h6{
  display: inline;
}
.ms-open-chat .ms-chat-user-info .ms-chat-time{
  font-size: 12px;
  color: #aeaeae;
  float: right;
}
.ms-open-chat .ms-chat-user-info p{
  font-size: 14px;
  color: #aeaeae;
  margin-bottom: 0;
  width: 85%;
}
.ms-open-chat .ms-chat-user-info i.ms-delete-trigger{
  position: absolute;
  bottom: 0;
  right: 0;
}
.ms-has-new-msg .msg-count{
  position: absolute;
  top: -2px;
  left: -2px;
  width: 17px;
  height: 17px;
  border: 1px solid #fff;
  background: #ff5757;
  border-radius: 50%;
  font-size:10px;
  text-align: center;
  color: #fff;
}
.ms-group-members li{
  width: 30px;
  float: left;
  margin-left: -10px;
}
.ms-group-members li:first-child{
  margin-left: 0;
}
.ms-group-members li img{
  border-radius: 50%;
  border: 2px solid #fff;
}
.ms-group-members li.ms-group-count{
  width: auto;
  margin-left: 7px;
  margin-top: 6px;
  font-size: 12px;
  color: #aeaeae;
}
.ms-chat-conversations .ms-chat-controls li{
  margin-right: 15px;
  width: 40px;
  height: 40px;
  background-color: #f0f0f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ms-chat-conversations .ms-chat-controls li i{
  color: #3a3952;
  font-size: 18px;
}
.ms-chat-conversations .ms-chat-controls li:last-child{
  margin-right: 0;
}
.ms-chat-bubble{
  align-items: flex-start;
}
.ms-chat-text{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
}
.ms-chat-incoming .ms-chat-text{
  align-items: flex-start;
}
.ms-chat-text p{
  position: relative;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: #fff;
  display: block;
  max-width: 80%;
  text-align: left;
}
.ms-chat-bubble .ms-chat-time{
  font-size: 12px;
}
.ms-chat-bubble.ms-chat-outgoing .ms-chat-text p:first-child{
  border-top-right-radius: 0;
}
.ms-chat-bubble.ms-chat-outgoing .ms-chat-text p:first-child::after{
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  border-top: 0px solid transparent;
  border-left: 9px solid #ff5757;
  border-bottom: 12px solid transparent;
}
.ms-chat-bubble.ms-chat-incoming .ms-chat-text p:first-child{
  border-top-left-radius: 0;
}
.ms-chat-bubble.ms-chat-incoming .ms-chat-text p:first-child::after{
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  border-top: 0px solid transparent;
  border-right: 9px solid #f0f0f7;
  border-bottom: 12px solid transparent;
}
.ms-chat-bubble.ms-chat-outgoing{
  flex-direction: row-reverse;
}
.ms-chat-bubble.ms-chat-outgoing .ms-chat-img{
  margin-left: 1rem;
}
.ms-chat-bubble.ms-chat-outgoing .ms-chat-text{
  text-align: right;
}
.ms-chat-bubble.ms-chat-incoming .ms-chat-text{
  text-align: left;
}
.ms-chat-bubble.ms-chat-outgoing .ms-chat-text p{
  background-color: #ff5757;
}
.ms-chat-bubble.ms-chat-outgoing .ms-chat-time{
  text-align: right;
}
.ms-chat-bubble.ms-chat-incoming .ms-chat-img{
  margin-right: 1rem;
}
.ms-chat-bubble.ms-chat-incoming .ms-chat-text p{
  background-color: #f0f0f7;
  color: #3a3952;
}
.ms-chat-textbox{
  padding: 1rem;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 25px;
}
.ms-chat-textbox .ms-chat-input{
  flex: 1 1 auto;
}
.ms-chat-textbox .ms-chat-input input{
  width: 92%;
  border: 0;
  margin: 0 1rem;
  outline: none;
  background-color: transparent;
}
.ms-chat-text-controls{
  border-left: 1px solid rgba(0,0,0,.1);
}
.ms-chat-text-controls span{
  padding-left: 1rem;
}
.ms-chat-text-controls span i,
.ms-chat-vn i{
  font-size: 18px;
  cursor: pointer;
  color: #878793;
}
.ms-hoverable-dropdown{
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  color: #aeaeae;
}
.ms-hoverable-dropdown i{
  font-size: 18px;
}
.ms-open-chat:hover{
  background-color: #e9ecef;
}
.ms-open-chat.selected{
  background-color: #e9ecef;
  border-left: 3px solid #ff5757;
}
.ms-chat-user-container:hover .ms-hoverable-dropdown{
  display: block;
}

/* To-do-list */

.ms-todo-list-container div.form-group input{
  width: 87%;
}
.ms-to-do-task{
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.ms-to-do-task input.ms-task-input{
  border: 0;
  width: calc(100% - 65px);
  background-color: transparent;
}
.ms-to-do-task input.ms-task-input:disabled{
  background: #fff;
}
.ms-task-block li.ms-to-do-task:last-child{
  border-bottom: 0;
  padding-bottom: 0;
}
.ms-to-do-task .ms-confirm-task-form{
  display: inline;
}
.ms-to-do-task.task-complete span{
  text-decoration: line-through;
}
.ms-task-block .ms-list-item{
  margin-bottom: 0;
  padding: 0.75rem 0;
}
.ms-todo-options{
  display: flex;
  align-items: center;
  height: 100%;
}
.ms-todo-options.ms-todo-nav{
  justify-content: flex-end;
}
.ms-todo-options li{
  padding: 0 15px;
  border-right: 1px solid rgba(0,0,0,.1);
}
.ms-todo-options li:last-child{
  border-right: 0;
}

/* Email */
.ms-email-container .ms-email-img{
  width: 50px;
  text-align: center;
}
.ms-email{
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.ms-email:hover{
  background-color: #e9ecef;
}
.ms-email-details{
  overflow: hidden;
  width: 100%;
}
.ms-email .ms-email-details h6{
  display: inline;
  font-size: 14px;
}
.ms-email:hover .ms-hoverable-dropdown{
  display: block;
}
.ms-email .ms-hoverable-dropdown{
  bottom: -9px;
  right: -5px;
}
.ms-email .ms-email-time{
  font-size: 12px;
  color: #aeaeae;
  position: absolute;
  top: 1rem;
  right: 0;
  margin-right: 25px;
}
.ms-email .ms-email-time i{
  font-size: 16px;
  margin-right: 10px;
}
.ms-email .ms-email-details p{
  font-size: 14px;
  color: #aeaeae;
  margin-bottom: 0;
}
.ms-email .ms-email-sender{
  font-size: 18px;
  font-weight: 400;
  color: #3a3952;
  display: block;
}
a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
    background-color: #ff5757!important;
}
.ms-email-list{
  border-bottom: 1px solid rgba(0,0,0,.1);
}
.ms-email-aside .ms-email-list.bb-0{
  border-bottom: 0;
}
.ms-email-panel .ms-panel-body{
  display: flex;
}
.ms-email-panel .ms-email-main{
  flex: 1 1 auto;
  width: calc( 100% - 100px );
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ms-email-list .ms-list-item{
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
.ms-email-list .ms-list-item a{
  display: block;
}
.ms-email-list .ms-list-item a span{
  color: #878793;
  float: right;
}
.ms-email-list .ms-list-item i{
  color: #878793;
  margin-right: 10px;
  font-size: 18px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.ms-email-list .ms-list-item i.ms-has-notification::after{
  top: -3px;
}
.ms-email-list .ms-email-label{
  font-size: 13px;
  padding-bottom: 0;
  color: #878793;
}
.ms-email-list .ms-active-email a,
.ms-email-list .ms-active-email i,
.ms-email-list .ms-list-item a:hover,
.ms-email-list .ms-list-item a:hover i,
.ms-email-list .ms-list-item a:focus i{
  color: #ff5757;
}
.ms-email-aside{
  padding: 1.5rem;
  border-right: 1px solid rgba(0,0,0,.1);
}
.ms-email-config{
  margin-top: 150px;
}
.ms-email-pagination{
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  margin-bottom: 0;
  width: auto;
}
.ms-email-pagination li{
  display: inline-block;
  margin: 0 5px;
}
.ms-email-pagination-item a{
  padding: 7px 10px;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 5px;
  display: block;
}
.ms-email-pagination-item a:hover{
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #34334a;
}
.ms-email-pagination-item a:focus{
  color: #34334a;
}
.ms-email-header{
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(0,0,0,.1);
  display: flex;
  justify-content: space-between;
}
.ms-email-options{
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
}
.ms-email-options .dropdown{
  display: inline-block;
}
.ms-email-options li{
  padding: 0 15px;
  border-right: 1px solid rgba(0,0,0,.1);
}
.ms-email-options li:first-child{
  padding-left: 0;
}
.ms-email-options li:last-child{
  border-right: 0;
  padding-right: 0;
}
.ms-email-options i{
  font-weight: 700;
  margin-right: 5px;
}
.ms-email-options .has-chevron,
.ms-email-options .has-chevron::after{
  color: #878793;
}
.ms-email-options .has-chevron::after{
  top: 1px;
  right: -20px;
}
.ms-email-content .ms-email img{
  width: 50px;
}
.ms-email-content .ms-email{
  padding: 1rem 1.5rem;
}
.ms-email-controls .ms-pin-email{
  margin-right: 15px;
  color: #878793;
  cursor: pointer;
}
.ms-email.pinned .ms-email-controls .ms-pin-email,
.ms-email .ms-email-controls .ms-pin-email:hover{
  color: #f39001;
}

/* Web Analytics */
.ms-graph-metrics .btn{
  margin-top: 0;
  min-width: auto;
  padding: 0.825rem 1rem;
}
.ms-site-select .dropdown-menu{
 width: 250px;
 margin-top: 1rem;
}
.ms-active-site{
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.ms-site-select .selected span{
  color: #5cb85c;
}
.ms-active-site.has-chevron::after{
  top: 1px;
  right: auto;
  left: 100%;
  margin-left: 5px;
}
.ms-graph-meta{
  margin-top: 1.5rem;
}
.ms-graph-meta ul{
  margin-bottom: 0;
}
.ms-graph-meta li{
  margin-right: 1.5rem;
}
.ms-graph-meta h3{
  margin-top: 0.35rem;
  margin-bottom: 0;
}
.ms-graph-labels{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ms-graph-labels span{
  width: 15px;
  height: 7px;
  border-radius: 25px;
  display: inline-block;
}
.ms-graph-labels.column-direction{
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.ms-graph-decrease{
  background-color: #ec4e3f;
}
.ms-graph-regular{
  background-color: #000000;
}
.ms-graph-labels p{
  display: inline-block;
  margin: 0 20px 0 10px;
  color: #3a3952;
}
.ms-graph-labels p:last-child{
  margin-right: 0;
}
.ms-trend{
  position: relative;
  width: 150px;
}
.ms-trend canvas{
  position: absolute;
  top: 0;
  width: 50% !important;
  height: 40px !important;
}
.ms-device-sessions .ms-panel-footer{
  border-top: 1px solid rgba(0,0,0,.1);
}
.ms-device-sessions .ms-panel-footer h6{
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.ms-device-sessions .ms-device{
  margin-bottom: 1.5rem;
}

.load{
  margin: auto;
 text-align: center;
 cursor: pointer;
}

.space{
  margin-right: 15px;
}
.ms-device-sessions .ms-device i{
  font-size: 24px;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ms-device-sessions .ms-device p{
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 700;
}
.ms-device-sessions .ms-device p:last-child{
  font-size: 14px;
  font-weight: 400;
}
.ms-device-sessions .progress{
  height: 2rem;
  border-radius: 0;
  margin-bottom: 0;
}
.ms-panel-footer .ms-device{
  margin-bottom: 0;
}

.website-performance-canvas,
.ms-graph-canvas{
  position: relative;
  flex: 1 1 auto;
  height: 50px;
  width: 90%;
}
.ms-website-performance li.ms-list-item{
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 1.5rem;
}
.ms-website-performance li:last-child{
  border-bottom: 0;
}
.website-performance-canvas canvas,
.ms-graph-canvas canvas{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.website-performance-meta{
  text-align: right;
  margin-left: 1rem;
}
.website-performance-meta p:first-child,
.ms-facebook-engagements .ms-graph-meta p:first-child{
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 700;
}
.ms-average-users-country .jvectormap-container,
.ms-average-users-country .jvectormap-container>svg,
.ms-average-users-country .vector-map{
  height: 275px;
}

/* Crypto Currency */
.ms-news-update{
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background-color: #ff5757;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ms-news-update::after{
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 70px solid #fff;
  border-left: 35px solid transparent;
}
.ms-news-update span{
  text-transform: uppercase;
  color: #fff;
  margin-left: 15px;
}
.ms-news-flash-container{
  overflow: hidden;
  padding: 1.2rem;
}
.ms-news-flash-container li p{
  font-weight: 400;
}
.ms-crypto-overview-slider .slick-list{
  cursor: grab;
  padding: 1.5rem;
}
.ms-crypto-overview-slider .slick-slide:focus{
  outline: none;
}
.ms-crypto-overview i{
  margin-right: 5px;
}
.ms-crypto-rating a.has-chevron{
  color: #878793;
}
.ms-crypto-rating a.has-chevron i{
  margin-right: 5px;
}
.ms-crypto-rating a.has-chevron::after{
  top: 1px;
  right: -20px;
}
.ms-crypto-rating .dropdown{
  margin: 1rem 0;
}
.ms-crypto-orders .nav-tabs .nav-item .nav-link{
  background-color: transparent;
  color: #ff5757;
  border: 1px solid #ff5757;
  padding: 0.825rem 1rem;
  border-radius: .2rem;
  margin-top: 0;
}
.ms-crypto-orders .nav-tabs .nav-item:first-child .nav-link{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ms-crypto-orders .nav-tabs .nav-item:not(:first-child) .nav-link{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ms-crypto-orders .nav-tabs .nav-item .nav-link.active{
  background-color: #ff5757;
  color: #fff;
}
.ms-quick-stats{
  display: flex;
  align-items: center;
}
.ms-quick-stats i{
  font-size: 34px;
  padding: 10px;
  width: 44px;
  height: 44px;
  margin-bottom: 0.825rem;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ms-quick-stats p{
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 700;
}
.ms-stats-grid{
  text-align: center;
  padding: 0.7rem;
  border-left: 1px solid rgba(0,0,0,.1);
  border-bottom: 1px solid rgba(0,0,0,.1);
  flex: 0 0 50%;
}
.ms-quick-stats:last-child .ms-stats-grid{
  border-bottom: 0;
}
.ms-crypto-amount{
  width: 180px;
}
.ms-crypto-orders-expanded .ms-trend{
  width: auto;
}
.ms-news-highlight{
  border-top: 3px solid #f8f8f8;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.ms-news-highlight:hover,
.ms-dark-theme .ms-news-highlight:hover{
  border-top-color: #ff5757;
  opacity: 0.8;
}
.ms-news-highlight h2{
  margin: 1rem 0;
}

/* Social Media */

.ms-social-media-followers{
  display: flex;
  align-items: center;
}
.ms-social-media-followers i{
  font-size: 24px;
  padding: 10px;
  width: 44px;
  height: 44px;
  margin-bottom: 0.825rem;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ms-social-media-followers p{
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 700;
}
.ms-social-grid{
  text-align: center;
  padding: 0.7rem;
  border-left: 1px solid rgba(0,0,0,.1);
  border-bottom: 1px solid rgba(0,0,0,.1);
  flex: 0 0 50%;
}
.ms-social-media-followers:last-child .ms-social-grid{
  border-bottom: 0;
}
table td .progress{
  margin-bottom: 0;
  background: #fff;
  border-radius: 0;
}
.ms-facebook-engagements p{
  margin-bottom: 0;
}
.ms-twitter-feed .media{
  align-items: flex-start;
}
.ms-feed .media .ms-img-round{
  margin-right: 1rem;
}
.ms-twitter-feed .ms-feed-user,
.ms-twitter-feed .ms-feed-user + p{
  display: inline-block;
}
.ms-feed p{
  font-size: 14px;
}
.ms-feed li.ms-list-item{
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 1.5rem;
  margin-bottom: 0;
}
.ms-feed li.ms-list-item:last-child{
  border-bottom: 0;
}
.ms-feed-controls{
  display: flex;
  margin-top: 1rem;
}
.ms-feed-controls span{
  margin-right: 2rem;
}
.ms-feed-controls span i{
  font-size: 18px;
}
.ms-facebook-feed .ms-feed-user{
  margin-bottom: 0;
}
.ms-facebook-feed .media{
  margin-bottom: .5rem;
}
.ms-fb-feed-img{
  border-radius: 5px;
  margin: 1rem 0;
  width: 100%;
}
.ms-fb-post-meta{
  display: flex;
  justify-content: center;
}
.ms-fb-post-meta i{
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff5757;
  color: #fff;
  margin-right: 5px;
  font-size: 12px;
}
.ms-facebook-feed .ms-feed-controls{
  justify-content: space-between;
  border-top: 1px solid rgba(0,0,0,.1);
  padding-top: 1rem;
}
.ms-facebook-feed .ms-feed-controls span{
  margin: 0;
  cursor: pointer;
}
.ms-instagram-feed .ms-feed-controls{
  margin-top: 0;
}
.ms-instagram-feed .ms-feed-controls i{
  font-size: 24px;
  margin-right: 1rem;
  font-weight: 400;
  cursor: pointer;
}
.ms-instagram-feed .ms-group-members{
  margin: 1rem 0;
}
.ms-facebook-engagements li.ms-list-item{
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 1.5rem;
  margin-bottom: 0;
}
.ms-facebook-engagements li.ms-list-item:last-child{
  border-bottom: 0;
}
.ms-facebook-engagements .ms-graph-meta{
  text-align: left;
  margin-top: 0;
  margin-right: 1rem;
}
.ms-facebook-page-selection{
  padding: 1.5rem;
  padding-bottom: 0;
}
.ms-facebook-page-selection .dropdown{
  display: inline-block;
  margin: 1.5rem 0 0;
}
.ms-facebook-page-selection .dropdown a.has-chevron::after{
  top: 0;
  right: auto;
  left: 100%;
}

/* Client Management */

.ms-recent-support-tickets .ms-list-item:hover{
  background-color: #e9ecef;
}

/* Dark Theme */
.ms-dark-theme, .ms-dark-theme a,
.ms-dark-theme h1, .ms-dark-theme h2,
.ms-dark-theme h3, .ms-dark-theme h4,
.ms-dark-theme h5, .ms-dark-theme h6,
.ms-dark-theme table, .ms-dark-theme .ms-news-flash *,
.ms-dark-theme .ms-infographics-widget.has-graph .ms-card-body + p,
.ms-dark-theme p:not([class*="ms-text-"]), .ms-dark-theme span:not([class*="ms-text-"]),
.ms-dark-theme .ms-quick-bar-item i, .ms-dark-theme .ms-configure-qa i,
.ms-dark-theme .text-disabled, .ms-dark-theme .ms-crypto-rating a.has-chevron,
.ms-dark-theme .ms-email .ms-email-sender, .ms-dark-theme .ms-text-dark, .ms-dark-theme .ms-text-dark:hover, .ms-dark-theme .ms-text-dark:focus,
.ms-dark-theme .ms-chat-conversations .ms-chat-controls li i, .ms-dark-theme .ms-notification-widget i,
.ms-dark-theme .accordion .card-header span, .ms-dark-theme .accordion .card-body, .ms-dark-theme .progress-rounded .progress-value,
.ms-dark-theme .page-item:not(.active) .page-link, .ms-dark-theme .page-item:not(.active-circle) .page-link,
.ms-dark-theme .page-item:not(.active-bordered) .page-link, .ms-dark-theme .page-item:not(.active-round) .page-link,
.ms-dark-theme-panel *, .ms-dark-theme .modal-min .ms-form-group.has-icon i, .ms-dark-theme .modal-max .ms-form-group.has-icon i,
.ms-dark-theme .swal2-popup .swal2-title, .ms-dark-theme .swal2-popup .swal2-content, .ms-dark-theme pre, .ms-dark-theme .ms-icons-list > div > i,
.ms-dark-theme .ms-email-list .ms-list-item i, .ms-dark-theme .ms-delete-trigger, .ms-dark-theme .ms-to-do-task input.ms-task-input,.ms-dark-theme .side-nav .menu-item a span{
  color: #fff;
}
.ms-dark-theme .ms-open-chat .ms-chat-user-info .ms-chat-time, .ms-dark-theme .ms-group-members li.ms-group-count,
.ms-dark-theme .ms-email-list .ms-email-label, .ms-dark-theme .ms-email-list .ms-list-item a span,
.ms-dark-theme .ms-email-options .has-chevron, .ms-dark-theme .ms-email-options .has-chevron::after,
.ms-dark-theme .dataTables_info, .ms-dark-theme .breadcrumb-item.active, .ms-dark-theme .breadcrumb-item + .breadcrumb-item::before,
.ms-dark-theme .ms-chat-textbox .ms-chat-input input{
  color: #5c6dc0;
}
.ms-dark-theme a:hover, .ms-dark-theme a:active {
  color: #ff5757;
}
.ms-dark-theme .ms-news-highlight{
  border-top: 3px solid #323a67;
}
.ms-dark-theme .body-content,
.ms-dark-theme .progress,
.ms-dark-theme .breadcrumb,
.ms-dark-theme .ms-chat-conversations .ms-chat-controls li,
.ms-dark-theme .ms-card-header .ms-btn-icon,
.ms-dark-theme .ms-notification-widget i,
.ms-dark-theme .accordion .card-header,
.ms-dark-theme .page-item.disabled .page-link,
.ms-dark-theme pre, .ms-dark-theme .ms-open-chat.selected,
.ms-dark-theme .irs--flat .irs-line,
.ms-dark-theme .irs--sharp .irs-line,
.ms-dark-theme .irs--round .irs-line, .ms-dark-theme .irs--square .irs-line{
  background-color: #323a67;
}
.ms-dark-theme .page-link, .ms-dark-theme .input-group-text, .ms-dark-theme .custom-file-label::after{
  background-color: #252851;
  color: #fff;
}
.ms-dark-theme .pagination-light .page-item:not(.active) .page-link, .ms-dark-theme .pagination-light .page-item:not(.active-circle) .page-link,
.ms-dark-theme .pagination-light .page-item:not(.active-round) .page-link{
  background-color: #323a67;
  border-color: #323a67;
}
.ms-dark-theme .ms-panel.ms-panel-hoverable:hover,
.ms-dark-theme .ms-recent-support-tickets .ms-list-item:hover{
  background-color: rgba(0,0,0,.05);
}

.ms-dark-theme .page-item.active .page-link, .ms-dark-theme .pagination-light .page-item.active .page-link{
  color: #fff;
  background-color: #ff5757;
  border-color: #ff5757;
  z-index: 0;
}
.ms-dark-theme .page-link,
.ms-dark-theme .pagination.pagination-expanded,
.ms-dark-theme .pagination-input input[type="number"],
.ms-dark-theme .pagination-expanded .page-item-expanded input[type="number"],
.ms-dark-theme .custom-file-label, .ms-dark-theme .page-item.disabled .page-link{
  border-color: rgba(0,0,0,0.1);
}
.ms-dark-theme .progress-rounded{
  border-color: #323a67;
}
.ms-dark-theme .ms-chat-bubble.ms-chat-incoming .ms-chat-text p{
  background-color: #323a67;
  color: #fff;
}
.ms-dark-theme .ms-chat-bubble.ms-chat-incoming .ms-chat-text p:first-child::after{
  border-right-color: #323a67;
}
.ms-dark-theme .accordion .card-header[aria-expanded="true"]{
  background-color: #252851;
}
.ms-dark-theme input,
.ms-dark-theme button,
.ms-dark-theme input:focus,
.ms-dark-theme button:focus{
  outline: 0;
  box-shadow: none;
}
.ms-dark-theme .ms-switch-slider,
.ms-dark-theme .ms-auth-form button.btn-social-login{
  background-color: #323a67;
}
.ms-dark-theme .ms-switch-slider:before{
  background-color: #5c6dc0;
}
.ms-dark-theme .navbar,
.ms-dark-theme .ms-quick-bar-list,
.ms-dark-theme .ms-quick-bar > .nav-tabs .nav-item .nav-link,
.ms-dark-theme .ms-quick-bar, .ms-dark-theme #preloader-wrap{
  background-color: #2a2e5b;
}
.ms-dark-theme .ms-quick-bar,
.ms-dark-theme .navbar,
.ms-dark-theme .table-bordered{
  border-color: #242750;
}
.ms-dark-theme .side-nav .logo-sn, .ms-dark-theme .side-nav .menu-item ul{
  background-color: #252851;
}
.ms-dark-theme .side-nav{
  background-color: #292e5a;
}
.ms-dark-theme .ms-panel,
.ms-dark-theme .ms-card,
.ms-dark-theme .dropdown-menu,
.ms-dark-theme .ms-quick-bar .ms-quick-bar-content,
.ms-dark-theme .card,
.ms-dark-theme .breadcrumb.breadcrumb-arrow li,
.ms-dark-theme .pagination.pagination-expanded,
.ms-dark-theme-panel, .ms-dark-theme .pagination-expanded .page-item:first-child .page-link::after,
.ms-dark-theme .pagination-expanded .page-item:last-child .page-link::after,
.ms-dark-theme .pagination-expanded .page-item:not(.active) .page-link:hover::after,
.ms-dark-theme .ms-list .ms-list-item.bordered, .ms-dark-theme .modal-content, .ms-dark-theme .ms-form-wizard,
.ms-dark-theme .ms-profile-overview, .ms-dark-theme .ms-content-wrapper.ms-auth,
.ms-dark-theme .irs--round .irs-handle, .ms-dark-theme .irs--square .irs-handle{
  background-color: #252851;
}
.ms-dark-theme .swal2-popup{
  background: #292d5a;
}
.ms-dark-theme .modal-header{
  border-bottom-color: rgba(0,0,0,.1);
}
.ms-dark-theme .modal-footer,
.ms-dark-theme .swal2-popup .swal2-footer{
  border-top-color: rgba(0,0,0,.1);
}
.ms-dark-theme .breadcrumb.breadcrumb-arrow li::after,
.ms-dark-theme .ms-form-wizard ul[role="tablist"] li::before{
  border-left-color: #292d5a;
}
.ms-dark-theme .ms-news-update::after{
  border-bottom-color: #292d5a;
}
.ms-dark-theme .breadcrumb.breadcrumb-arrow li::before{
  border-left-color: #323a67;
}
.ms-dark-theme .table td,
.ms-dark-theme .table th{
  border-color: #242750;
}


.ms-dark-theme .pagination-input input[type="number"],
.ms-dark-theme .pagination-expanded .page-item-expanded input[type="number"],
.ms-dark-theme .custom-file-label, .ms-dark-theme .irs--flat .irs-min, .ms-dark-theme .irs--flat .irs-max,
.ms-dark-theme .irs--modern .irs-min, .ms-dark-theme .irs--modern .irs-max,
.ms-dark-theme .irs--round .irs-min, .ms-dark-theme .irs--round .irs-max,
.ms-dark-theme .irs--big .irs-min, .ms-dark-theme .irs--big .irs-max{
  background-color: #323a67;
  color: #5c6dc0;
}
.ms-dark-theme input.ms-form-input::placeholder,
.ms-dark-theme input::placeholder,
.ms-dark-theme textarea::placeholder{
  color: #5c6dc0;
  opacity: 1;
}
.ms-dark-theme input.ms-form-input:-ms-input-placeholder,
.ms-dark-theme input::-ms-input-placeholder,
.ms-dark-theme textarea::-ms-input-placeholder,
.ms-dark-theme .ms-chat-textbox .ms-chat-input input{
  color: #5c6dc0;
}
.ms-dark-theme .form-control{
  border: 0;
}
.ms-dark-theme .nav-tabs .nav-item .nav-link{
  background-color: #323a67;
  color: #5c6dc0;
}
.ms-dark-theme .nav-tabs .nav-item .nav-link.active{
  color: #fff;
  background-color: #ff5757;
}
.ms-dark-theme .ms-crypto-orders .nav-tabs .nav-item .nav-link{
  background-color: transparent;
  color: #ff5757;
}
.ms-dark-theme .dropdown-menu .media:hover,
.ms-dark-theme .ms-open-chat:hover,
.ms-dark-theme .ms-email:hover{
  background-color: #323a67;
}
.ms-dark-theme .thead-light thead{
  background-color: rgba(0,0,0,.15);
}
.ms-dark-theme .ms-has-new-msg .msg-count,
.ms-dark-theme .ms-chat-status::after,
.ms-dark-theme .ms-group-members li img{
  border-color: #292d5a;
}
.ms-dark-theme .dropdown-divider{
  border-top-color: #242750;
}

.ms-dark-theme .ms-quick-bar-item a:not(.active):hover span,
.ms-dark-theme .ms-quick-bar-item a:not(.active):hover i,
.ms-dark-theme .ms-quick-bar .ms-quick-bar-list:not(.ms-qa-configure-mode) + .ms-configure-qa a:hover i,
.ms-dark-theme .ms-quick-bar .ms-quick-bar-list:not(.ms-qa-configure-mode) + .ms-configure-qa a:hover span{
  color: #eee;
}

@media(max-width: 1024px){

  .ms-card.ms-card-fh{
    height: auto;
  }
  .ms-todo-options{
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 0 0;
    height: auto;
  }
  .ms-todo-options.ms-todo-nav{
    align-items: flex-end;
  }
  .ms-todo-options li{
    padding: 5px 15px;
    border-right: 0;
  }
  .ms-chat-text p{
    max-width: 100%;
  }
  .ms-hoverable-dropdown,
  .ms-email .ms-email-time{
    display: block;
  }
  .ms-email .ms-email-time{
    float: right;
    position: relative;
    top: auto;
  }
  /* .ms-auth-bg,
  .ms-auth-col:first-child{
    display: none;
  } */
  .ms-content-wrapper.ms-auth{
    min-height: 100vh;
  }
  .ms-website-performance li.ms-list-item{
    float: left;
    width: 50%;
  }
  .ms-facebook-engagements li.ms-list-item{
    float: left;
    width: 50%;
  }

}

@media(max-width: 768px){

  .ms-auth-bg,
  .ms-auth-col:first-child{
    display: none;
  }
  
  .display-4{
    font-size: 2rem;
  }
  .pagination-expanded .page-item-expanded{
    display: none;
  }
  .ms-wizard-vertical.ms-form-wizard ul[role="tablist"]{
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 25px;
  }
  .ms-wizard-vertical.ms-form-wizard .content,
  .ms-wizard-vertical.ms-form-wizard .actions,
  .ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li:not(:first-child) a{
    margin-left: 0;
  }
  .ms-wizard-vertical.ms-form-wizard ul[role="tablist"] li:last-child{
    margin-bottom: 0;
  }
  .section-title{
    margin: 1.5rem 0;
  }
  .ms-todo-options,
  .ms-todo-options.ms-todo-nav{
    align-items: center;
  }
  .ms-email-panel .ms-panel-body{
    flex-direction: column;
  }
  .ms-email-config{
    margin-top: 1.5rem;
  }
  .ms-email-panel .ms-email-main{
    width: 100%;
  }
  .ms-email .ms-email-time{
    float: none;
  }
  .ms-email-details{
    overflow: visible;
  }
  .ms-email-controls,
  .ms-email-header,
  .ms-email .ms-email-details p,
  .ms-email-pagination li:first-child{
    display: none;
  }
  .ms-auth-form{
    display: block;
  }
  .ms-auth-form form{
    padding: 3rem 1rem;
  }
  .ms-auth-form form{
    width: 100%;
  }
  .ms-email .ms-hoverable-dropdown{
    bottom: 100%;
  }
  .ms-profile-overview{
    height: auto;
  }
  .ms-profile-cover{
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    padding-bottom: 1.5rem;
    flex-direction: column;
  }
  .ms-profile-user-buttons{
    flex: 0 0 auto;
    text-align: left;
  }
  .ms-profile-cover img{
    margin-bottom: 1rem;
  }
  .ms-profile-user-info, .ms-profile-user-buttons{
    padding: 0;
  }
  .ms-profile-navigation{
    position: relative;
    left: auto;
    bottom: auto;
  }
  .ms-lock-screen-weather,
  .ms-lock-screen-time,
  .ms-lock-screen-nav{
    display: none;
  }
  .ms-graph-meta ul{
    display: none;
  }
  .ms-selected-website select,
  .ms-selected-website h2{
    margin-bottom: 1rem;
  }
  .ms-selected-website button{
    width: 100%;
  }
  .ms-portfolio-minimal .ms-portfolio-item::before{
    height: 100%;
  }
  .ms-portfolio-minimal .ms-portfolio-item .ms-portfolio-item-content{
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .ms-graph-labels.column-direction{
    display: none;
  }
  .ms-panel-footer .ms-device{
    margin-bottom: 1.5rem;
  }

}
@media(max-width: 540px){

  .ms-form-wizard ul[role="tablist"] li a{
    padding: 10px;
  }
  .ms-form-wizard ul[role="tablist"] li:not(:first-child) a{
    margin-left: 20px;
  }
  .ms-form-wizard ul[role="tablist"] li::before,
  .ms-form-wizard ul[role="tablist"] li::after,
  .ms-wizard-pill.ms-form-wizard ul[role="tablist"] li:last-child a::after,
  .ms-wizard-round.ms-form-wizard ul[role="tablist"] li{
    display: none;
  }
  .ms-form-wizard ul[role="tablist"]{
    display: block;
  }
  .ms-form-wizard.style1-wizard ul[role="tablist"] li:first-child{
    border-radius: 0;
  }
  .ms-wizard-pill.ms-form-wizard ul[role="tablist"] li{
    border-radius: 25px;
  }
  .ms-chat-conversations .ms-chat-controls li:nth-child(2),
  .ms-form-wizard ul[role="tablist"] li{
    margin-right: 0;
  }
  .ms-chat-conversations .ms-chat-controls li:last-child,
  .ms-email-widget .ms-email-options{
    display: none;
  }
  .ms-panel-header .d-flex:first-child{
    flex-direction: column;
  }
  .ms-panel-header .d-flex:first-child .btn,
  .ms-wizard-pill.ms-form-wizard ul[role="tablist"] li,
  .style1-wizard.ms-form-wizard ul[role="tablist"] li{
    margin-top: 1.5rem;
  }
  .ms-panel-header .d-flex:first-child .ms-graph-metrics.nav li{
    flex: 0 0 auto;
  }
  .ms-crypto-widget .nav-tabs.has-gap .nav-item .nav-link{
    margin-right: 0;
  }
  .ms-news-update{
    position: relative;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    margin-bottom: 1.5rem;
    width: calc(100% + 3rem);
    height: 50px;
    justify-content: center;
  }
  .ms-news-update::after{
    display: none;
  }
  .ms-website-performance li.ms-list-item{
    float: none;
    width: 100%;
  }
  .ms-facebook-engagements li.ms-list-item{
    float: none;
    width: 100%;
  }
  .ms-widget .ms-chat-header .ms-chat-user-info{
    display: none;
  }
  .btn-group{
    flex-direction: column;
  }
  .modal-footer{
    display: block;
  }
  .ms-crypto-rating .dropdown{
    margin: 1.5rem 0;
  }

}

/* Settings Panel */
.ms-settings-toggle{
  position: fixed;
  bottom: 0;
  right: 50%;
  z-index: 4;
  width: 90px;
  height: 40px;
  margin-right: -45px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
body:not(.ms-settings-open) .ms-settings-toggle:hover{
  height:50px;
}
.ms-settings-toggle i{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  -webkit-box-shadow: 0 -2px 16px rgba(0,0,0,.1);
  box-shadow: 0 -2px 16px rgba(0,0,0,.1);
}
.ms-settings-panel{
  position: fixed;
  bottom: -200px;
  left: 0;
  width: 100%;
  height: 200px;
  padding: 1.5rem;
  background-color: #fff;
  z-index: 1040;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.ms-dark-theme .ms-settings-panel,
.ms-dark-theme .ms-settings-toggle i{
  background-color: #252851;
}
.ms-settings-open .ms-settings-panel{
  bottom: 0;
}
.ms-settings-open .ms-settings-toggle{
  bottom: 200px;
}
.ms-settings-links{
  display: flex;
  width: 100px;
}
.ms-settings-links li a{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}
.ms-settings-links li a i{
  font-size: 22px;
}

/* Welcome Page */

.ms-banner{
  position: relative;
  background-color: #fff;
  height: 100%;
  z-index: 2;
}
.ms-banner h2{
  font-weight: 700;
}
.ms-banner-text{
  margin: 5rem auto 15rem;
}
.ms-banner p{
  font-size: 1rem;
}
.ms-banner h1,
.ms-banner p{
  color: #fff;
}
.welcome-title{
  font-size: 38px;
}
.welcome-subtitle{
  margin-bottom: 2rem;
  font-size: 14px;
  text-align: center;
}
.ms-dashboard-description{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 80%;
  margin: 0 auto;
}
.ms-text-container h2{
  font-size: 2rem;
  margin-bottom: 1rem;
}

.ms-demo-img,
.ms-text-container{
  position: relative;
  margin-top: 5rem;
}
.ms-tables-preview{
  background-color: #f5f4fa;
  padding: 5rem 2rem;
}
.ms-color-schemes{
  padding: 5rem 15px;
}
.ms-color-schemes .ms-dashboard-description{
  max-width: 400px;
  float: right;
  margin: 0 2rem;
}
.ms-tables-preview .ms-dashboard-description{
  max-width: 400px;
  justify-content: flex-start;
  margin: 0;
}
.ms-features{
  position: relative;
  margin-top: 2rem;
  z-index: 1;
  margin: 7rem 0;
}
.ms-features::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(76,54,188, 0.9);
  z-index: -1;
}
.ms-feature{
  padding: 2rem 0;
  margin: 2rem 0;
  border: 1px solid rgba(255,255,255,0.4);
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.ms-feature:hover{
  background-color: rgba(68,92,200, 0.4);
  border-color: rgba(68,92,200, 0.4);
}
.ms-feature i{
  color: rgb(71, 50, 187);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: 0 auto 1rem auto;
  font-size: 22px;
}
.ms-feature p{
  color: #fff;
}
.ms-feature h3{
  font-size: 28px;
  color: #fff;
}
.ms-infographics i{
  font-size: 34px;
  margin-bottom: 2rem;
}
.ms-infographics .infographic{
  margin: 2rem 0;
}
.ms-welcome-footer{
  background-color: rgb(76,54,188);
  text-align: center;
  margin-top: 7rem;
}
.ms-welcome-footer p{
  color: #fff;
}
.ms-welcome-btn{
  background-color: rgb(76,54,188);
  -webkit-box-shadow: 0 0 24px rgba(0,0,0,.4);
  box-shadow: 0 0 24px rgba(0,0,0,.4);
  color: #fff;
  padding: 0.75rem 3rem;
}
.ms-welcome-btn-light{
  background-color: #fff;
  -webkit-box-shadow: 0 0 24px rgba(0,0,0,.4);
  box-shadow: 0 0 24px rgba(0,0,0,.4);
  color: rgb(76,54,188);
  padding: 0.75rem 3rem;
  font-weight: 700;
}
.ms-welcome-btn-outline{
  background-color: transparent;
  -webkit-box-shadow: 0 0 24px rgba(0,0,0,.4);
  box-shadow: 0 0 24px rgba(0,0,0,.4);
  color: #fff;
  border: 2px solid #fff;
  padding: 0.75rem 3rem;
  font-weight: 700;
}
.ms-welcome-btn-light:hover,
.ms-welcome-btn-light:focus,
.ms-welcome-btn-light:not(:disabled):not(.disabled):active{
  background-color: #fff;
  color: rgb(76,54,188);
}
.ms-welcome-btn:hover,
.ms-welcome-btn:focus,
.ms-welcome-btn:not(:disabled):not(.disabled):active{
  background-color: rgb(76,54,188);
  color: #fff
}
.ms-preview-img-container{
  position: relative;
  height: 230px;
  overflow: hidden;
  border-radius: 5px;
}

/* Fixes */
.db-header-title{
  font-size: 32px;
  margin-bottom: 2rem;
}

.bg-black{
  background-color: #000000;
}

.bg-red{
  background-color: #ff5757;
}

.color-red{
  color: #ff5757;
}

.ui-sortable-handle .media-body h5,
.ui-draggable .media-body h5{
      font-size: 13px;
    font-weight: 600;
}

.ms-card .new.meta h6{
  margin-bottom: 0;
}

.ms-card .new{
  margin-bottom:15px;
}

.wrapper-new2 h6{
  margin-bottom: 0px;
}

.wrapper-new2{
  align-items: center;
}

.fw-6{
  font-weight: 600;
}

.ms-card-heading-title{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.red-text{
  color:  #ff5757;
}

.green-text{
  color: green;
}

.black-text{
  color: #000000;
}

.order-circle .progress-rounded{
  margin-bottom: 0rem;
}

.ms-device-sessions h4,
.ms-website-performance h4{
  font-size: 14px;
}

.no-margin{
  margin-bottom: 0rem;
}

.no-margin .ms-card-heading-title p{
  margin-bottom: 0;
}

.ms-recent-support-tickets .ms-feed-user{
  font-size: 16px;
}

.ms-recent-support-tickets h6{
  font-size: 14px;
}

.badge{
  vertical-align: middle;
  font-size: 12px;
}

.ms-card .new.meta p{
  margin-bottom: 0;
}

.ms-card.ms-widget{
  border-radius: 0px;
}

.ms-active-site,
.ms-graph-meta h2,
.ms-profile-widget h2,
.ms-infographics-widget h2{
  font-size: 24px;
}

.modal-content h1{
  font-size: 24px;
}

.modal-title.has-icon.ms-icon-round i{
  line-height: 0.5;
}

.form-control.new{
  font-size: 12px;
}

/* Responsive */

@media(max-width:1300px){
  .no-margin .ms-card-heading-title p span{
    display: block;
  }
}

@media(max-width:1200px){
  .no-margin .ms-card-heading-title p span{
    display: inline-flex;
  }
}

@media(max-width:1114px){
  .ms-switch-slider.ms-switch-discount-2{
    top: 49%;
  }
.ms-switch-slider.ms-switch-discount-3{
    top: 68%;
  }
}

@media (max-width:1024px){
  .ms-nav-list .ms-nav-item .ms-has-notification i{
    color: #ff5757;
  }
}

@media (max-width:991px){
  .no-margin{
    margin-bottom: 2rem;
  }
  .order-circle .progress-rounded{
    margin-bottom: 2rem;
  }

}

@media(max-width:540px){
  .align-left{
    align-self: flex-start!important;
  }
  .new{
    display: block;
  }
  .new.form-control {
    width: 100%;
    height: 45px;
    margin-top: 24px;
  }
}

@media(max-width:360px){
  .ms-switch-slider.ms-switch-discount-2{
    top: 53%;
  }
.ms-switch-slider.ms-switch-discount-3{
    top: 70%;
  }

  .ms-switch .ms-status-switch{
    left: 75%;
  }
}
@media (max-width: 1200px) {
	.mb-lg-10 {
		margin-bottom: 10px;
	}
	.mb-lg-20 {
		margin-bottom: 20px;
	}
	.mb-lg-30 {
		margin-bottom: 30px;
	}
	.mb-lg-40 {
		margin-bottom: 40px;
	}
	.mb-lg-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 992px) {
	.mb-md-10 {
		margin-bottom: 10px;
	}
	.mb-md-20 {
		margin-bottom: 20px;
	}
	.mb-md-30 {
		margin-bottom: 30px;
	}
	.mb-md-40 {
		margin-bottom: 40px;
	}
	.mb-md-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 767px) {
	.mb-sm-10 {
		margin-bottom: 10px;
	}
	.mb-sm-20 {
		margin-bottom: 20px;
	}
	.mb-sm-30 {
		margin-bottom: 30px;
	}
	.mb-sm-40 {
		margin-bottom: 40px;
	}
	.mb-sm-80 {
		margin-bottom: 80px;
	}
}
@media (max-width: 576px) {
	.mb-xs-10 {
		margin-bottom: 10px;
	}
	.mb-xs-20 {
		margin-bottom: 20px;
	}
	.mb-xs-30 {
		margin-bottom: 30px;
	}
	.mb-xs-40 {
		margin-bottom: 40px;
	}
	.mb-xs-80 {
		margin-bottom: 80px;
	}
}
.ms-panel-body.order-circle h6{
  margin-bottom: 20px;;
}
.reminder-modal{
  border-radius: .3rem;
  max-width: 500px;
  width: 100%;
}
.ms-card-footer .btn-primary.text-disabled:not(:disabled):not(.disabled):active,
.ms-card-footer .btn-primary.text-disabled:focus,
.ms-card-footer .btn-primary.text-disabled{
  color:#878793;
  background: none;
  margin:0;
  padding: 0;
  text-align: left;
  border: none;
  box-shadow: none;
}
.custom-dropdown .nav-link.ms-hoverable-dropdown,
.hide-after:after,
#userDropdown:after,
.ms-card .ms-card-body .dropdown a:after,
.ms-card-footer .btn-primary.text-disabled:after{
  display: none;
}
.show>.btn-primary.remove-dropdownbtn.dropdown-toggle,
.remove-dropdownbtn:not(:disabled):not(.disabled):active,
.remove-dropdownbtn:focus,
.remove-dropdownbtn{
  color:#878793;
  background: none;
  margin:0;
  padding: 0;
  text-align: left;
  border: none;
  box-shadow: none;
  min-width: auto;
}
.ms-email:hover .custom-dropdown .nav-link.ms-hoverable-dropdown,
.custom-dropdown .nav-link{
  display: inline;
}
.carousel-control-next span, 
.carousel-control-prev span{
background-image: none;
}
.carousel-control-prev span:before{
    content: "keyboard_arrow_left";
}
.carousel-control-next span:before{
    content: "keyboard_arrow_right";
}
.carousel-control-next span:before, 
.carousel-control-prev span:before{
  font-family: 'Material Icons';
  
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.google-marker{
  background-image: url('../img/marker.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 27px;
  height: 40px;
}
.ms-form-wizard ul[role="tablist"] li:first-child>a:before{
  content: 'General Information';
}
.ms-form-wizard ul[role="tablist"] li:nth-child(2)>a:before{
  content: 'Business Information';
}
.ms-form-wizard ul[role="tablist"] li:last-child>a:before{
  content: 'Insurance Details';
}
.ms-form-wizard.style3-wizard ul[role="tablist"] li>a:before{
  display: none;
}

.required{
  color:#f9423c;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image:unset !important;
}